import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid, Dialog, DialogContent, Divider, DialogActions } from "@mui/material";
import { Star, StarBorder, StarHalf, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled, Favorite, PlaylistAddCheck, CloseOutlined, Close, FileUploadOutlined } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2 } from "./assets";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";
import HostCatalogueController, { Props } from "./HostCatalogueController";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
const MIN_WINDOW_HEIGHT = 900

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class HostCatalogue extends HostCatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} openModal={this.openEditLogoModal} newLogo={
          this.state.imagePreviewUrl
            ? this.state.imagePreviewUrl
            : this.state.logo && typeof this.state.logo === "object" && "name" in this.state.logo
              ? URL.createObjectURL(this.state.logo)
              : this.state.logo || undefined
        } id='1' />
        <div style={webStyles.container1} className="container1">
          <StyledWrapperSetting>
            <Box className="header-section-host" id="header-section-host-1">
              <Box display="flex" flexDirection="row" gap="20px" justifyContent="space-between">
                <Box display="flex" gap="20px" flexDirection="row">
                  <img src={podcastImage} alt="Podcast" className="podacst-image-host" />
                  <Box className="podcast-header-content-host">
                    <Typography variant="h5" component="h2" style={{ fontWeight: "900", fontSize: "30px", lineHeight: "40px", fontFamily: "Nexa-Heavy" }}>The True Podcast</Typography>
                    <Typography className="podcast-name-host">{this.state.podcastName}</Typography>
                    <Box alignItems="center" display="flex" id="stars">
                      {[...Array(this.state.fullStars)].map((_, index) => (
                        <IconButton style={{ padding: 0 }} key={`full-${index}`} >
                          <Star style={{ color: '#fff' }} />
                        </IconButton>
                      ))}
                      {this.state.hasHalfStar && (
                        <IconButton key="half-star" style={{ padding: 0 }}>
                          <StarHalf style={{ color: '#fff' }}  />
                        </IconButton>
                      )}
                      {[...Array(this.state.emptyStars)].map((_, index) => (
                        <IconButton  key={`empty-${index}`} style={{ padding: 0 }}>
                          <StarBorder style={{ color: '#fff' }} />
                        </IconButton>
                      ))}
                    </Box>
                    <Box display="flex" marginBottom="10px" gap="8px">
                      <Button className="left-section-buttons" variant="outlined">Rate or Review</Button>
                      <Button className="left-section-buttons" variant="outlined" >Share Channel</Button>
                    </Box>
                    <Typography className="total-reviews" >{this.state.totalReviews}K Reviews</Typography>
                    <Box display="flex" marginTop="10px" gap="10px">
                      <Button variant="contained" data-test-id="follow-button" className="left-section-bottom-buttons" style={{ backgroundColor: (this.state.followButtonText === "Follow" ? "#FF5722" : "black") }} onClick={() => this.handleFollowButton()}>{this.state.followButtonText}</Button>
                      <Button  className="left-section-bottom-buttons" variant="contained" ><AttachMoney style={{ width: "24px", height: "24px" }} />Subscribe</Button>
                      <IconButton className="podcast-header-info-icon" ><InfoOutlined /></IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" flexDirection="row" alignItems="flex-start" style={{ marginTop: '40px' }}>
                  <Box display="flex" gap="20px" flexDirection="column">
                    <Box flexDirection="row" display="flex"  gap="20px">
                      <Box display="flex" gap="10px" flexDirection="column">
                        <Button variant="outlined" style={{ backgroundColor: '#FF0807', color: '#fff', borderColor: '#FF0807', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><Laptop style={{ width: "24px", height: "20px" }} />Webinar</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', height: "44px", width: '164px', borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><img src={tshirtIcon} />Buy Swag</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontFamily: "Nexa-Heavy", fontWeight: "bold" }}><AttachMoney style={{ width: "24px", height: "24px" }} />Support Host</Button>
                      </Box>
                      <Box display="flex" gap="10px" flexDirection="column">
                        <Button variant="outlined" style={{ borderColor: '#FF0807', backgroundColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><ShoppingCartOutlined style={{ width: "24px", height: "20px" }} />Buy Now</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', width: '164px', height: "44px", color: '#fff', borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><MenuBook style={{ width: "24px", height: "20px" }} />Learn More</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", textTransform: 'capitalize', borderRadius: "8px", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><LocalOfferOutlined style={{ width: "24px", height: "20px" }} />Get Offer</Button>
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" flexDirection="column">
                      <Button variant="outlined" style={{ color: '#fff', height: "44px", width: '345px', borderRadius: "8px", border: "1px solid white", fontWeight: "bold", textTransform: "capitalize", fontFamily: "Nexa-Heavy" }} startIcon={<Phone />}>
                        Contact Host
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="left-body-section-tabs" id="left-body-section-tabs-1">
              <Box sx={{ display: 'flex', gap: '22px' }}>
                <Button variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('Episodes')} sx={{ borderRadius: (this.state.selectedTab === 'Episodes' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'Episodes' ? '53px' : '40px') }}>Episodes</Button>
                <Button variant="outlined" data-test-id="tab-button" className="section-tabs" onClick={() => this.handleTabChanges('Community')} sx={{ borderRadius: (this.state.selectedTab === 'Community' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'Community' ? '53px' : '40px') }}>Community</Button>
                <Button variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('ChannelInfo')} sx={{ borderRadius: (this.state.selectedTab === 'ChannelInfo' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'ChannelInfo' ? '53px' : '40px') }}>Channel Info</Button>
              </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px', marginBottom: "6%" }}>
              <div className="scrollableWrapper" id= "scrollableWrapper">
                <Box className="left-body-section" sx={{ overflowY: 'scroll', maxHeight: '64rem' }} id= "left-body-section-1">
                  <Card className="podcast-card" id= "podcast-card-1">
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={podcastImage}
                      className="podcast-media"
                      alt="Podcast episode"
                    />
                    <Box className="podcast-inner-box" id= "podcast-inner-box-1">
                      <CardContent className="podcast-inner-box-content" id= "podcast-inner-box-content-1">
                        <Typography component="div" variant="h6" color="#fff" className="podcast-inner" id= "podcast-inner-1">
                          The Cost Of Success
                        </Typography>
                        <Typography variant="subtitle1" color="#fff" component="div" id="ep-10" className="podcast-inner">
                          The True
                        </Typography>
                        <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner" id= "podcast-inner-3">
                          Episode 10
                        </Typography>
                        <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                          Sed ut perspiciatis unde omnis iste natus error sit ...
                          <Link style={{ color: '#fff' }} id="ep-10-link">See More</Link>
                        </Typography>
                      </CardContent>
                      <Box className="podcast-action-buttons" id= "podcast-action-buttons-1">
                        <IconButton aria-label="like" id="ep-10-like" sx={{ color: 'white' }}>
                          <ThumbUpOffAlt />
                        </IconButton>
                        <Typography sx={{ marginRight: '15px', color: 'white' }} className="podcast-inner">1.4K</Typography>
                        <IconButton sx={{ color: 'white' }} aria-label="comment">
                          <ChatBubbleOutline />
                        </IconButton>
                        <Typography sx={{ marginRight: '15px', color: 'white' }} className="podcast-inner">985</Typography>
                        <IconButton sx={{ color: 'white' }} aria-label="share">
                          <Share />
                        </IconButton>
                        <Typography className="podcast-inner" sx={{ color: 'white' }}>1.2K</Typography>
                      </Box>
                    </Box>
                    <Box className="podcast-saving-options" id="ep-10-buttons" data-test-id= "podcast-saving-options-1">
                      <Button variant="outlined" startIcon={<FavoriteBorder style={{ marginLeft: "20%" }} />} className="podcast-save-button">
                        <Typography style={{ fontWeight: "900", lineHeight: "26px", fontSize: "10px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                      </Button>
                      <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd style={{ marginLeft: "20%" }} />}>
                        <Typography style={{ fontWeight: "900", fontSize: "10px", whiteSpace: "nowrap", lineHeight: "26px", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                      </Button>
                    </Box>
                    <Box className="play-icon" id="ep-10-play-button-host">
                      <PlayCircleFilled style={{ color: 'red', fontSize: '40px', marginBottom: '-62%' }} />
                    </Box>
                  </Card>
                  <Card className="podcast-card">
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      alt="Podcast episode"
                      className="podcast-media"
                      image={podcastImage}
                    />
                    <Box className="podcast-inner-box" id= "podcast-inner-box-2">
                      <CardContent className="podcast-inner-box-content" id= "podcast-inner-box-content-2">
                        <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
                          The Cost Of Process
                        </Typography>
                        <Typography variant="subtitle1" color="#fff" component="div" id="ep-9" className="podcast-inner">
                          The True
                        </Typography>
                        <Typography variant="body2"  sx={{ marginTop: '10px' }}  color="#fff"className="podcast-inner">
                          Episode 9
                        </Typography>
                        <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                          Sed ut perspiciatis unde omnis iste sit voluptatem accusantium...
                          <Link style={{ color: '#fff' }} id="ep-9-link">See More</Link>
                        </Typography>
                      </CardContent>
                      <Box className="podcast-action-buttons" id= "podcast-action-buttons-2">
                        <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-9-like-host">
                          <ThumbUpOffAlt />
                        </IconButton>
                        <Typography className="podcast-inner" sx={{ color: 'white', marginRight: '15px' }}>1.3K</Typography>
                        <IconButton aria-label="comment" sx={{ color: 'white' }} >
                          <ChatBubbleOutline />
                        </IconButton>
                        <Typography className="podcast-inner" sx={{ color: 'white', marginRight: '15px' }}>984</Typography>
                        <IconButton aria-label="share" sx={{ color: 'white' }} >
                          <Share />
                        </IconButton>
                        <Typography className="podcast-inner" sx={{ color: 'white' }}>1.3K</Typography>
                      </Box>
                    </Box>
                    <Box className="podcast-saving-options" id="ep-9-button-host">
                      <Button className="podcast-save-button" variant="outlined" startIcon={<FavoriteBorder style={{ marginLeft: "20%" }} />}>
                        <Typography style={{ fontWeight: "900",  fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                      </Button>
                      <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd style={{ marginLeft: "20%" }} />}>
                        <Typography style={{ fontWeight: "900", lineHeight: "26px", fontSize: "10px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                      </Button>
                    </Box>
                    <Box className="play-icon" id="ep-9-play-button-host">
                      <PlayCircleFilled style={{ marginBottom: '-62%', color: 'red', fontSize: '40px' }} />
                    </Box>
                  </Card>
                  <Card className="podcast-card" id= "podcast-card-3">
                    <CardMedia
                      sx={{ width: 151 }}
                      component="img"
                      image={podcastImage}
                      alt="Podcast episode"
                      className="podcast-media"
                    />
                    <Box className="podcast-inner-box" id= "podcast-inner-box-3">
                      <CardContent className="podcast-inner-box-content" >
                        <Typography component="div" className="podcast-inner" variant="h6" color="#fff">
                          The Cost Of Failure
                        </Typography>
                        <Typography variant="subtitle1" component="div" id="ep-8" color="#fff" className="podcast-inner">
                          The True
                        </Typography>
                        <Typography variant="body2" color="#fff" className="podcast-inner" sx={{ marginTop: '10px' }}>
                          Episode 8
                        </Typography>
                        <Typography variant="body2" color="#fff" sx={{fontFamily: 'Nexa-Regular', marginTop: '10px' }}>
                          Sed ut perspiciatis iste natus error sit voluptatem accusantium...
                          <Link style={{ color: '#fff' }} id="ep-8-link-host">See More</Link>
                        </Typography>
                      </CardContent>
                      <Box className="podcast-action-buttons" id= "podcast-action-buttons-3">
                        <IconButton aria-label="like" sx={{ color: 'red' }} id="ep-8-like">
                          <ThumbUpOffAlt />
                        </IconButton>
                        <Typography className="podcast-inner" sx={{ color: 'red', marginRight: '15px' }}>1.5K</Typography>
                        <IconButton aria-label="comment" sx={{ color: 'white' }}>
                          <ChatBubbleOutline />
                        </IconButton>
                        <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">988</Typography>
                        <IconButton aria-label="share" sx={{ color: 'white' }}>
                          <Share />
                        </IconButton>
                        <Typography sx={{ color: 'white' }} className="podcast-inner">1.9K</Typography>
                      </Box>
                    </Box>
                    <Box className="podcast-saving-options" id="ep-8-button">
                      <Button variant="outlined" className="podcast-save-button" style={{ backgroundColor: "#000000", borderColor: "#000000" }} startIcon={<Favorite sx={{ color: 'red' }} />}>
                        <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Saved</Typography>
                      </Button>
                      <Button variant="outlined" className="podcast-save-button" style={{ backgroundColor: "#000000", borderColor: "#000000" }} startIcon={<PlaylistAddCheck />}>
                        <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Added</Typography>
                      </Button>
                    </Box>
                    <Box className="play-icon" id="ep-8-play-button">
                      <PlayCircleFilled style={{ color: 'red', marginBottom: '-62%', fontSize: '40px' }} />
                    </Box>
                  </Card>
                  <Card className="podcast-card" id= "podcast-card-4">
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={podcastImage}
                      alt="Podcast episode"
                      className="podcast-media"
                    />
                    <Box className="podcast-inner-box" id="podcast-inner-box-5">
                      <CardContent className="podcast-inner-box-content"  >
                        <Typography variant="h6" component="div" color="#fff" className="podcast-inner">
                          The Cost Of You
                        </Typography>
                        <Typography variant="subtitle1" color="#fff" id="ep-7" component="div" className="podcast-inner">
                          The True
                        </Typography>
                        <Typography color="#fff" sx={{ marginTop: '10px' }} variant="body2" className="podcast-inner">
                          Episode 7
                        </Typography>
                        <Typography color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }} variant="body2">
                          Sed ut iste natus error sit voluptatem accusantium...
                          <Link style={{ color: '#fff' }} id="ep-7-link-host">See More</Link>
                        </Typography>
                      </CardContent>
                      <Box className="podcast-action-buttons" id="podcast-action-buttons-5">
                        <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-7-like-host">
                          <ThumbUpOffAlt />
                        </IconButton>
                        <Typography className="podcast-inner" sx={{ color: 'white', marginRight: '15px' }}>2.3K</Typography>
                        <IconButton sx={{ color: 'white' }}  aria-label="comment">
                          <ChatBubbleOutline />
                        </IconButton>
                        <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">888</Typography>
                        <IconButton  aria-label="share" sx={{ color: 'white' }}>
                          <Share />
                        </IconButton>
                        <Typography sx={{ color: 'white' }} className="podcast-inner">4.0K</Typography>
                      </Box>
                    </Box>
                    <Box className="podcast-saving-options" id="ep-7-buttons">
                      <Button variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder style={{ marginLeft: "20%" }} />}>
                        <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                      </Button>
                      <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd style={{ marginLeft: "20%" }} />}>
                        <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                      </Button>
                    </Box>
                    <Box className="play-icon" id="ep-7-play-button">
                      <PlayCircleFilled style={{ color: 'red', marginBottom: '-62%', fontSize: '40px' }} />
                    </Box>
                  </Card>
                  <Card className="podcast-card" id="podcast-card-6">
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={podcastImage}
                      alt="Podcast episode"
                      className="podcast-media"
                    />
                    <Box className="podcast-inner-box" id="podcast-inner-box-6">
                      <CardContent className="podcast-inner-box-content"   >
                        <Typography component="div" color="#fff" className="podcast-inner" variant="h6">
                          The Cost Of Me
                        </Typography>
                        <Typography color="#fff" variant="subtitle1" component="div" id="ep-11" className="podcast-inner">
                          The True1
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: '10px' }} color="#fff" className="podcast-inner">
                          Episode 11
                        </Typography>
                        <Typography variant="body2" color="#fff" sx={{ fontFamily: 'Nexa-Regular', marginTop: '10px'}}>
                          Sed ut iste natus error sit accusantim...
                          <Link style={{ color: '#fff' }} id="ep-11-link-host">See More</Link>
                        </Typography>
                      </CardContent>
                      <Box className="podcast-action-buttons" id= "podcast-action-buttons-6">
                        <IconButton aria-label="like" sx={{ color: 'white' }} id="ep-11-like">
                          <ThumbUpOffAlt />
                        </IconButton>
                        <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.1K</Typography>
                        <IconButton sx={{ color: 'white' }} aria-label="comment" >
                          <ChatBubbleOutline />
                        </IconButton>
                        <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">770</Typography>
                        <IconButton aria-label="share" sx={{ color: 'white' }}>
                          <Share />
                        </IconButton>
                        <Typography sx={{ color: 'white' }} className="podcast-inner">5.1K</Typography>
                      </Box>
                    </Box>
                    <Box className="podcast-saving-options" id="ep-11-buttons">
                      <Button variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder style={{ marginLeft: "20%" }} />}>
                        <Typography style={{ fontWeight: "900", fontSize: "10px", lineHeight: "26px", whiteSpace: "nowrap", fontFamily: 'Nexa-Heavy' }}>Save For Later</Typography>
                      </Button>
                      <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd style={{ marginLeft: "20%" }} />}>
                        <Typography style={{ fontWeight: "900", lineHeight: "26px", whiteSpace: "nowrap", fontSize: "10px", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                      </Button>
                    </Box>
                    <Box className="play-icon" id="ep-11-play-button-host">
                      <PlayCircleFilled style={{ fontSize: '40px', color: 'red', marginBottom: '-62%' }} />
                    </Box>
                  </Card>
                </Box>
              </div>
              <Box sx={{ minHeight: '100vh'  }}>
                <Grid container  spacing={2} direction="column" className="right-body-section">
                  <Typography className="sponsored-title" id= "sponsored">Sponsored Recommendations</Typography>
                  <Grid item>
                    <Card className="recommended-cards" id="recommended-cards-1">
                      <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ fontFamily: "Nexa-Heavy", color: '#fff' }}>
                            The True
                          </Typography>
                          <Typography sx={{ color: '#fff', fontFamily: "Nexa-Regular" }} variant="body2">
                            Cost of Success Ep. 9
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_1}
                        sx={{
                          width: "20%",
                          height: "100%",
                          objectFit: 'cover',
                          marginLeft: 'auto',
                          marginRight: "-11px"
                        }}
                        alt="The True Episode"
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" id= "recommended-cards-2">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                            All Consuming
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                            Fantomy Ep. 12
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_2}
                        alt="The True Episode"
                        sx={{
                          height: "100%",
                          width: "20%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" id= "recommended-cards-3">
                      <Box sx={{ flexDirection: 'column',display: 'flex' }}>
                        <CardContent>
                          <Typography sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }} variant="h6">
                            This American Life
                          </Typography>
                          <Typography variant="body2" sx={{color: '#fff', fontFamily: 'Nexa-Regular'}}>
                            Love your era Ep. 8
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        sx={{
                          width: "20%",
                          height: "100%",
                          objectFit: 'cover',
                          marginLeft: 'auto',
                          marginRight: "-11px",
                        }}
                        image={recommended_episode_3}
                        alt="The True Episode"
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Box flexDirection="column" display="flex"  gap="25px">
                  <Typography variant="h6" sx={{ color: '#fff', marginTop: 4, fontFamily: 'Nexa-Heavy' }}>
                    Advertising
                  </Typography>
                  <Box
                    component="img"
                    src={ad_1}
                    sx={{ height: '20rem', borderRadius: "13px" }}
                    alt="Burger Ad"
                  />
                  <Box
                    component="img"
                    alt="Heinz Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                    src={ad_2}
                  />
                </Box>
              </Box>
            </Box>
          </StyledWrapperSetting>
          <div style={{
            backgroundColor: "#030303"
          }}>
            <Footer navigation={this.props.navigation} id={"1"} />
          </div>
        </div>
        <div style={{
          paddingLeft: "calc(6% + 135px)",
          marginTop: "1%"
        }} className="audioPlayer">
          <AudioPlayerComponent navigation={this.props.navigation} id="" />
        </div>
        <Dialog open={this.state.openEditLogoModal} onClose={this.handleCloseEditLogoDialog} PaperProps={{
          style: {
            width: '45rem',
            margin: '0 auto',
            borderRadius: "8px 8px 30px 8px",
            height: '48%'
          },
        }}
          componentsProps={{
            backdrop: {
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(4px)',
              },
            },
          }}
          maxWidth={false}
        >
          <IconButton
            onClick={this.handleCloseEditLogoDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
            data-test-id="second-dialog-close-icon"
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C", padding: "30px" }}>
            <Typography variant="h6" align="center" style={{ color: "#fff", marginBottom: "2%", marginLeft: "45%", marginTop: "4%", fontFamily: 'Nexa-Heavy' }}>
              New Logo Image:
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: "space-between"
              }}
            >
              <div style={{ width: '30%', marginLeft: "7%" }}>
                <img
                  src={
                    this.state.imagePreviewUrl
                      ? this.state.imagePreviewUrl
                      : (this.state.logo && typeof this.state.logo === "object" && "name" in this.state.logo
                        ? URL.createObjectURL(this.state.logo)
                        : this.state.logo || "")
                  }
                  alt="Logo"
                  style={{ width: '108%', height: '12.7rem', borderRadius: '10px', border: "1px solid white", padding: "0% 4% 0% 4%" }}
                />
              </div>
              <div
                style={{
                  padding: '20px',
                  border: '2px dashed #fff',
                  borderRadius: '10px',
                  width: '42%',
                  height: '10rem',
                  backgroundColor: '#3D3D3D',
                  marginRight: "5%"
                }}
              >
                {this.state.tempLogoUrl ? (
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <img src={this.state.tempLogoUrl} alt="Preview" style={{ width: '95%', height: '10rem', borderRadius: '8px' }} />
                    <IconButton
                      onClick={() => this.setState({ imagePreviewUrl: "", selectedFile: null, tempLogoUrl: "" })}
                      sx={{ position: 'absolute', top: 15, right: 31, color: '#000000' }}
                      data-test-id="logo-url"
                    >
                      <Close />
                    </IconButton>
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Box>
                      <FileUploadOutlined style={{ color: '#FF0807' }} />
                    </Box>
                    <Typography variant="body2" style={{ color: "#fff", fontFamily: 'Nexa-Heavy', width: "75%", marginLeft: "13%" }}>
                      Drag & Drop Your File Here OR
                    </Typography>
                    <Button
                      variant="contained"
                      component="label"
                      style={{ marginTop: '10px', backgroundColor: '#FF4500', fontFamily: 'Nexa-Heavy', width: "50%", height: "22%", borderRadius: "8px", textTransform: "none" }}
                    >
                      Browse file
                      <input
                        hidden
                        accept="image/*"
                        data-test-id="upload_file"
                        type="file"
                        onChange={this.handleFileChange}
                      />
                    </Button>
                    <Typography variant="body2" style={{ color: "#fff", marginTop: '10px', fontFamily: 'Nexa-Heavy', width: "75%", marginLeft: "13%" }}>
                      Max File Size: 200mb Supports JPG and PNG files
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '18%' }}>
            <Button onClick={this.handleCancelClick} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', width: "25%", height: "65%", borderRadius: "5px" }} data-test-id="cancel">
              Cancel
            </Button>
            <Button
              onClick={this.handleDoneClick}
              variant="contained"
              disabled={!this.state.selectedFile}
              style={{
                backgroundColor: ResolveCondition((this.state.selectedFile !== null), "#FF4500", "#A9A9A9"),
                textTransform: 'capitalize',
                fontFamily: 'Nexa-Heavy',
                width: "20%",
                height: "65%",
                borderRadius: "5px"
              }}
              data-test-id="done"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyles = {
  container1: {
    height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
    overflowY: "scroll" as const,
    scrollbarWidth: "thin",
    scrollbarColor: "red gray",
    width: "99%",
    backgroundColor: "#1C1917",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "gray",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  } as const
}
const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  // width: "100%",
  paddingRight: "5%",
  paddingLeft: "calc(6% + 135px)",
  background: "#1C1917",
  "& *": {
    boxSizing: "border-box",
  },
  "& .header-section-host": {
    backgroundColor: "#44403C",
    width: "102%",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    padding: "1rem",
    // marginLeft: "90px",
    marginTop: "18px"
  },
  "& .podacst-image-host": {
    width: "269px",
    height: "259px",
    borderRadius: "5px",
    marginTop: "13px",
    marginLeft: "8px"
  },
  "& .podcast-name-host": {
    fontWeight: "900",
    fontSize: "40px",
    lineHeight: "56px",
    textTransform: "uppercase",
    fontFamily: "Nexa-Heavy"
  },
  "& .podcast-header-content-host": {
    color: "white",
    fontWeight: "bold",
    marginTop: "13px"
  },
  "& .podcast-header-info-icon": {
    width: "44px",
    height: '44px',
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#FF0807",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .left-section-buttons": {
    color: "white",
    border: "1px solid white",
    borderRadius: "8px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white",
      border: "1px solid white",
    }
  },
  "& .total-reviews": {
    fontSize: "18px",
    fontWeight: "900",
    fontFamily: "Nexa-Heavy",
    lineHeight: "22px",
    marginTop: "15px"
  },
  "& .left-section-bottom-buttons": {
    backgroundColor: "#FF5722",
    color: "white",
    borderRadius: "8px",
    height: '44px',
    width: "164px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    borderColor: "#FF5722",
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#FF5722",
      borderColor: "#FF5722",
    }
  },
  "& .left-body-section-tabs": {
    height: "52px",
    // width: "536px",
    // marginLeft: "90px",
    marginTop: "22px"
  },
  "& .scrollableWrapper": {
    height: "65rem",
    position: "relative",
    paddingRight: "20px",
    overflow: "hidden",
  },
  "& .section-tabs": {
    backgroundColor: '#44403C',
    color: 'white',
    borderColor: "#44403C",
    textTransform: "capitalize",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: '#44403C',
      borderColor: "#44403C",
    },
    marginBottom: "0px"
  },
  "& .left-body-section": {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    paddingRight: "10px",
    scrollbarColor: "#FF4500 #4D4D4D",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#4D4D4D",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF4500",
      borderRadius: "10px",
    },
  },
  "& .podcast-card": {
    display: 'flex',
    gap: "24px",
    backgroundColor: '#44403C',
    // width: "1005px",
    height: "242px",
    "&:first-of-type": {
      marginTop: "0px",
    },
    "&:not(:first-of-type)": {
      marginTop: "20px",
    }
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto',
    paddingBottom: 0
  },
  "& .podcast-inner-box": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "& .podcast-saving-options": {
    display: 'flex',
    flexDirection: 'column',
    gap: "9px"
  },
  "& .podcast-action-buttons": {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 10
  },
  "& .podcast-save-button": {
    color: 'white',
    borderColor: '#FF0807',
    backgroundColor: "#FF0807",
    borderRadius: "8px",
    marginLeft: "46%",
    marginRight: "0px",
    height: "26px",
    width: "118px",
    textTransform: "capitalize",
    gap: "8px",
    "&:hover": {
      borderColor: '#FF0807',
      backgroundColor: "#FF0807",
    },
    "&:not(:first-of-type)": {
      marginTop: "0px",
    },
    "&:first-of-type": {
      marginTop: "25px",
    }
  },
  "& .play-icon": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '10%',
    marginRight: "2%",
    marginTop: "19%"
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    marginTop: "34px",
    marginLeft: "44px",
    borderRadius: "3px"
  },
  "& .right-body-section": {
    marginTop: "-35px",
    // width: "47.8%", 
    // height: "33%"
  },
  "& .sponsored-title": {
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy',
    color: "#fff"
  },
  "& .podcast-inner": {
    fontFamily: 'Nexa-Heavy'
  },
  "& .recommended-cards": {
    display: 'flex',
    backgroundColor: '#44403C',
    color: '#fff',
    // width: '478px', 
    height: '80px',
    borderRadius: '6px',
    borderLeft: '5px solid red'
  }
});

// Customizable Area End
