import React from "react";

import {
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { podcastImage2} from "./assets";
import Slider from 'react-slick';
import AudioListWeb from "./AudioList.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web"
import Footer from "../../../blocks/navigationmenu/src/Footer.web"; 
import ImageCard from "./components/ImageCard.web";
import AudioCard from "./components/AudioCard.web";
import AudioPlayerComponent from "./AudioPlayerComponent.web";
interface PlaylistCardProps extends BoxProps {
  bgColor?: string; // Optional bgColor prop
}
const MIN_WINDOW_HEIGHT = 900

const CustomTypography = styled(Box)(({ theme }) => ({
  fontWeight: 900,
  fontSize: "30px",
  lineHeight: "40px",
  letter: "-0.5%",
  color: "#fff",
  marginBottom: "24px",
  fontFamily: "Nexa-Heavy",
}));

const PlaylistCard = styled(Box)<PlaylistCardProps>(({ theme,bgColor  }) => ({
  width: '141px',
  height: '60px',
  padding: '16px 12px 16px 9px',
  borderRadius: '6px',
  backgroundColor: bgColor ||"red",
  color: bgColor ==="white"? "black" : "white",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"
}));

const PlaylistContainer = styled(Box)<PlaylistCardProps>(({theme, bgColor }) => ({
  width: "600px",
  height: "92px",
  borderRadius:"6px",
  background:"#44403C",
  // padding:"16px 12px 16px 20px",
  borderLeft:`4px solid ${bgColor}`,
  color:"white",
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center"
}))

const webStyles = {
  container: {
    marginBottom: "2%",
    height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
    overflowY: "scroll" as const
  },
}
// Customizable Area End

import AudioLibraryController, {
  Props
} from "./AudioLibraryController";

export default class AudioLibrary extends AudioLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMedia = () => { return <AudioListWeb totalCount={this.state.totalCount} songs={this.state.audioList} pageNo={this.state.currentPageNo} handlePagination={this.handlePagination}  /> }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
          <NavigationMenu navigation={this.props.navigation} id='1' />
          <div style={webStyles.container}>
          <Box sx={{marginLeft:"14rem", marginBottom: "40px", width:"76%"}}> 
          <CustomTypography>
            Continue Listen
          </CustomTypography>
                      
          {this.state.continueListen.map((playlist, index) => (
            <Box key={index} display={"flex"} gap={2} mb={1}>
              <PlaylistCard bgColor={playlist.bgcolor}>
                <img
                  data-test-id="playlist"
                  src={playlist.playIcon} // Using the playIcon from the playlist
                  alt="playlist icon"
                  style={{ cursor: "pointer" }}
                />
                <Typography sx={{ fontWeight: 900, fontSize: "8px", lineHeight: "24px" }}>
                  {playlist.title}
                </Typography>
              </PlaylistCard>

              {playlist.playLists.map((song, songIndex) => (
                <PlaylistContainer bgColor={playlist.bgcolor} key={songIndex}>
                  <Box padding={"16px 12px 16px 20px"}>
                    <Typography sx={{ fontWeight: 900, fontSize: "1rem", lineHeight: "24px", fontFamily: 'Nexa-Heavy' }}>
                      {song.songTitle}
                    </Typography>
                    <Typography sx={{ fontWeight: 100, fontSize: "0.75rem", lineHeight: "22px", fontFamily: 'Nexa-Regular', color: "white" }}>
                      {song.songSubTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      data-test-id="playlist"
                      src={podcastImage2}
                      alt="playlist icon"
                      style={{ cursor: "pointer", width: "112px", height: "92px", objectFit:"cover" }}
                    />
                  </Box>
                </PlaylistContainer>
              ))}
            </Box>
          ))}

          <CustomTypography sx={{margin:"24px 0px", cursor: "pointer"}} onClick={this.handlePodCastFollowing}>
            Podcasts I'm Following
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"350px"}>
          <Slider {...this.getSlickSettings()}>
            {
              this.state.followingPodCasts.map((data, index) => (
                <ImageCard
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                following={data.following}
              />
              ))
            }
          </Slider>
          </Box>
          <CustomTypography data-test-id ={"saved_episode"} sx={{margin:"24px 0px", cursor: "pointer"}} onClick={this.handleSavedEpisode}>
            Saved Episodes
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"350px"}>
          <Slider {...this.getSlickSettings()}>
            {
              this.state.savedEpisode.map((data, index) => (
                <AudioCard 
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                selected={data.selected}
                added={data.added}
                />
              ))
            }
          </Slider>
          </Box>

          <CustomTypography sx={{margin:"24px 0px", cursor: "pointer"}} onClick={this.handleChannelLibrary}>
            Channel Library
          </CustomTypography>
          <Box sx={{position: 'relative'}} height={"360px"}>
          <Slider {...this.getSlickSettings()}>
          {
              this.state.channelLibrary.map((data, index) => (
                <AudioCard 
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                selected={data.selected}
                added={data.added}
                />
              ))
            }
          </Slider>
          </Box>
          </Box>  
          <Footer navigation={this.props.navigation} id={"1"} />
          </div>
          <div style={{
           paddingLeft: "calc(6% + 135px)"
           }}>
        </div>   
        <div style={{
          paddingLeft: "calc(6% + 135px)"
        }}>
          <AudioPlayerComponent navigation={this.props.navigation} id="" />
        </div>
      </>
      // Customizable Area End
    );
  }
}
