// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import { FormikHelpers } from 'formik';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface CategoryStructure {
  "id": "8",
  "type": "category",
  "attributes": {
    "name": "science",
    "image": {
      "url": "/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d2d6992001eb7f2f2028b8dd19912c9a5e62764/img.jpg"
    },
    "created_at": "2024-09-17T07:22:23.376Z",
    "updated_at": "2024-09-17T07:22:23.391Z"
  }
}

export interface SubCategoryStructure {
  "id": "1",
  "type": "sub_category",
  "attributes": {
    "id": 1,
    "name": "Pre Primary (kg)",
    "created_at": "2024-06-26T23:32:49.745Z",
    "updated_at": "2024-06-26T23:32:49.745Z",
    "categories": [],
    "image": null
  }
}

interface Host {
  first_name: string;
  last_name: string;
  email_address: string;
  location: string;
  host_bio: string;
}
interface PodcastFormValues {
  podcastCover: string;
  podcastName: string;
  subtitle: string;
  hostFirstName: string;
  hostLastName: string;
  hostEmail: string;
  hostLocation: string;
  hostBio: string;
  podcastLocation: string;
  state: string;
  city: string;
  podcastCategory: string;
  podcastSubCategory: string;
  hosts: Host[];
}
export interface S {
  files: unknown[],
  imageUrl: string,
  categoryList: CategoryStructure[],
  subCategoryList: SubCategoryStructure[],
  selectedCategory: any,
  selectedId: number | null,
  countryDropdown: Array<string>,
  selectedCountry: string,
  statesDropdown: Array<string>
}

export interface SS {
  id: any;
}

export default class HostSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  podcastCategoryApiCallId: string = "";
  podcastSubCategoryApiCallId: string = "";
  countryApiCallId: string = "";
  stateApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      files: [],
      imageUrl: "",
      categoryList: [],
      subCategoryList: [],
      selectedCategory: 1,
      selectedId: null,
      countryDropdown: [],
      selectedCountry: "",
      statesDropdown: [],
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleResForPodcastCategoryApi(from, message);
    this.handleResForPodcastSubCategoryApi(from, message);
    this.handleResForCountryApi(from, message);
    this.handleResForStatesApi(from, message)
  }


  async componentDidMount() {
    this.categoryApi();
    this.subCategoryApi();
    this.countryApi();
  }
  handleDrop = (event: React.DragEvent<HTMLDivElement>, setFieldValue: any) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length) {
      this.handleFile(files[0], setFieldValue);
    }
  };

  handleFile = (file: File, setFieldValue: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ imageUrl: reader.result as string });
      const base64String = reader.result as string;
      setFieldValue("podcastCover", base64String);
    };
    reader.readAsDataURL(file);
  };

  handleBoxClick = (id: number) => {
    this.setState({ selectedId: id });
  };

  categoryApi = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForPodcastCategoryApi = async (from: string, message: Message) => {
    if (
      this.podcastCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ categoryList: responseJson.data })
      }
    }
  };

  subCategoryApi = async () => {
    let token = await getStorageData("token");
    const selectedCategory = this.state.selectedCategory;
    const header = {
      token: JSON.parse(token),
    };
    let apiEndpoint = configJSON.getSubCategoryDropdown;

    if (selectedCategory && selectedCategory.id) {
      apiEndpoint = `${apiEndpoint}?category_id=${selectedCategory.id}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastSubCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleResForPodcastSubCategoryApi = async (from: string, message: Message) => {
    if (
      this.podcastSubCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ subCategoryList: responseJson.data })
      }
    }
  };
  countryApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForCountryApi = async (from: string, message: Message) => {
    if (
      this.countryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({ countryDropdown: responseJson })
      }
    }
  };
  statesApi = async () => {
    let token = await getStorageData("token")
    const header = {
      token: JSON.parse(token)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stateApiEndPoint}/${this.state.selectedCountry}/states`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForStatesApi = async (from: string, message: Message) => {
    if (
      this.stateApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({ statesDropdown: responseJson });
      }
    }
  };
  handleSubmit = async (values: PodcastFormValues, { resetForm, setFieldValue }: FormikHelpers<PodcastFormValues>) => {
    console.log(values, "values");
    resetForm();
    setFieldValue('podcastLocation', "");
    setFieldValue('hostLocation', "");   
    setFieldValue('state', "");   
    setFieldValue('city', "");   
    setFieldValue('podcastCategory', "");  
    setFieldValue('podcastSubCategory', "");   
    setFieldValue('hosts[0]location', "");   
    setFieldValue('hosts[1]location', "");   
    setFieldValue('hosts[2]location', "");  
    this.setState({imageUrl: ""}); 
    this.setState({ selectedId: 0 });
  }

}
// Customizable Area End
