// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,Dialog,
    DialogContent,
} from "@mui/material";
import HostCrowfundingResidentSupportController, {
    Props,
} from "./HostCrowfundingResidentSupportController";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { box1, box2, box3, edit, edit_w, goBack ,star} from "./assets";
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";

export default class HostCrowfundingResidentSupport extends HostCrowfundingResidentSupportController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object().shape({
    })
    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <StyledWrapper>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className="mainTitle" id="hostCrowfuding" style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "60px" }}>
                            <img
                                id="hostCrowfuding"
                                src={goBack}
                                alt="goBack"
                                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                                data-test-id="handleNavigation"
                            onClick={async()=>
                                {let dataExist =
                                    await getStorageData("monetizationCategories",true)
                                    this.handleRoutesResident(dataExist,"resident")}}
                            />
                            Back
                        </Box>
                        <Box className="upperBtn" style={{ marginLeft: "auto", marginRight: "10px" }}>
                            Re-Set Up Monetization
                        </Box>
                        <Box className="upperBtn" style={{ marginRight: "70px" }} id="hostCrowfuding">
                            Save For Later
                        </Box>
                    </Box>
                    <Box className="container">
                        <Box className="heading1">Crowdfunding or Resident Support</Box>
                        <Box className="heading2">Define the pre-fill for resident support</Box>
                        <Box className="boxContainer">
                            <Box className="boxS" style={{ backgroundColor: "#FFFFFF" }}>
                                <Box className="imgParent">
                                    <img src={box1} alt="box1" />
                                </Box>
                                <div className="title" style={{ color: "black" }}>Send Tip</div>
                                <div className="price" style={{ color: "black" }}>$1</div>
                                <Box
                                    className="icon"
                                    sx={{
                                        color: "black",
                                        margin: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: "6%"
                                    }}
                                >

                                    <img src={edit} alt="edit" />

                                </Box>

                            </Box>
                            <Box className="boxS" style={{ backgroundColor: "#000000" }}>
                                <Box className="imgParent">
                                    <img src={box2} alt="box1" />
                                </Box>
                                <div className="title" style={{ color: "white" }}>Send Tip</div>
                                <div className="price" style={{ color: "white" }}>$5</div>
                                <Box
                                    className="icon"
                                    sx={{
                                        color: "white",
                                        margin: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: "6%"
                                    }}
                                >
                                    <img src={edit_w} alt="edit" />
                                </Box>
                            </Box>
                            <Box className="boxS" style={{ backgroundColor: "#FF0807" }}>
                                <Box className="imgParent">
                                    <img src={box3} alt="box1" />
                                </Box>
                                <div className="title" style={{ color: "white" }}>Send Tip</div>
                                <div className="price" style={{ color: "white" }}>$10</div>
                                <Box
                                    className="icon"
                                    sx={{
                                        color: "white",
                                        margin: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginTop: "6%"
                                    }}
                                >
                                    <img src={edit_w} alt="edit" />
                                </Box>
                            </Box>
                        </Box>
                        <Box className="bContainer">
                            <Button className="button" style={{ background: "#B2B3B3" }}>Save</Button>
                            <Button className="button" type="submit" style={{ background: "#FF0807" }}
                            data-test-id = "modalOpen"
                            onClick={()=>{  this.setState({isModalOpen : true})}}
                            >Continue</Button>
                        </Box>
                    </Box>
                </StyledWrapper>
                <Dialog
                    open={this.state.isModalOpen}
                    PaperProps={{
                        sx: {
                            backgroundColor: "transparent",
                            borderRadius: "8px 8px 32px 8px",
                            width: "600px",
                            height: "363px",
                        },
                    }}
                    componentsProps={{
                        backdrop: {
                          style: { backgroundColor: 'rgba(0, 0, 0, 1)' },
                        },
                      }}
                >
                    <DialogContent
                        style={{ backgroundColor: "#44403C", color: "white", padding: 0, }}

                    >
                        <Box id="categoriesModal">
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10%" }}>
                                <img src={star} alt="star" />
                            </Box>

                            <h2 style={{ textAlign: "center", fontFamily: "Nexa-Heavy" }}>Success</h2>
                            <p style={{ textAlign: "center", fontFamily: "Inter", marginTop: "40px",marginBottom:"25px" }}>You are complete setting up<br /> monetization</p>
                        </Box>
                        <Box style={{
                            width: "600px", height: "104px", borderTop: "1px solid white", display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <a href="/">
                                <Button style={{
                                    height: "56px",
                                    width: "160px",
                                    borderRadius: "8px",
                                    backgroundColor: "#FF0807",
                                    textTransform: "capitalize",
                                    color: "white",
                                    fontWeight: 900,
                                    fontSize: "14px",
                                    margin: "auto",
                                }}>Go To My Channel</Button>
                            </a>
                        </Box>

                    </DialogContent>

                </Dialog>
            </>

        );
    }

}
const StyledWrapper = styled("div")({
    paddingRight: "3%",
    background: "black",
    paddingLeft: "calc(6% + 100px)",
    "& *": {
        boxSizing: "border-box",
    },
    "& .bContainer":{
        display: "flex",
        justifyContent: "space-around",
        width: "47%",
        margin: "auto",
        marginTop: "40px",
        paddingBottom: "80px",
        "@media (max-width: 1500px)": {
            flexDirection: "column", 
            alignItems: "center",
        },
    },
    "& .button": {
        height: "57px",
        width: "286px",
        color: "white",
        textTransform: "capitalize",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        borderRadius : "8px",
        fontSize: "14px",
        "@media (max-width: 1500px)": {
            marginTop : "20px"
         },
    },
    "& .price": {
        fontFamily: "Nexa-Heavy",
        fontSize: "50px",
        textAlign: "center",
        paddingTop: "15px"
    },
    "& .title": {
        fontFamily: "Nexa-Regular",
        fontSize: "24px",
        fontWeight: 800,
        textAlign: "center",
        paddingTop: "20px"
    },
    "& .imgParent": {
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        marginTop: "10%",
        flexDirection: "column",
        height: "56px",
        width: "56px",
    },
    "& .boxS": {
        height: "310px",
        width: "250px",
        border : "1px solid #B2B3B3",
        borderRadius: "26px",
        "@media (max-width: 1500px)": {
           marginTop : "40px"
        },
    },
    "& .boxContainer": {
        display: "flex",
        justifyContent: "space-around",
        width: "70%",
        margin: "auto",
        marginTop: "40px",
        paddingBottom: "40px",
        "@media (max-width: 1500px)": {
            flexDirection: "column", 
            alignItems: "center",
        },
    },
    "& .heading2": {
        textAlign: "center",
        fontFamily: "Nexa-Regular",
        fontSize: "16px",
        paddingTop: "1%"
    },
    "& .heading1": {
        fontSize: "30px",
        fontFamily: "Nexa-Heavy",
        textAlign: "center",
        paddingTop: "3%"
    },
    "& .upperBtn": {
        borderRadius: "8px",
        width: "auto",
        color: "white",
        height: "26px",
        fontWeight: 400,
        fontFamily: "Nexa-Heavy",
        fontSize: "16px",
        background: "#312F2D",
        marginRight: "26px",
    },
    "& .btn": {
        margin: "auto",
        marginTop: "3%",
        height: "56px",
        color: "white",
        cursor: "pointer",
        marginBottom: "2%",
        background: "#B2B3B3",
        width: "30%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        textTransform: "capitalize",
        fontSize: "14px",
        "&:disabled": {
            color: "white",
        },
        "&:hover": {
            background: "#B2B3B3",
        },
    },
    "& .mainTitle": {
        margin: "auto",
        marginLeft: "2%",
        height: "40px",
        fontFamily: "Nexa-Heavy",
        fontSize: "30px",
        marginBottom: "2%",
        color: "white",
        alignItems: "center",
        fontWeight: 900,
        display: "flex",
    },
    "& .container": {
        color: "white",
        margin: "auto",
        height: "auto",
        marginBottom: "3%",
        width: "93%",
        background: "#44403C",
        borderRadius: "15px"
    },
})
// Customizable Area End