import React from "react";
// Customizable Area Start
import { Box, Typography, LinearProgress, styled } from "@mui/material";
import Slider from "react-slick";
import { addBtn, addedBtn, heart, selectedBtn } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";

const MIN_WINDOW_HEIGHT = 900

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    const combinedImages = [...this.state.continueListeningPodcasts, ...this.getEmptyPlaceholders()]
    

    return (
      //Merge Engine DefaultContainer
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />
        <div style={webStyles.container}>
          <Box sx={{ height: "430px", width: "100%" }} data-test-id="continueListeningSection">
            <Typography style={webStyles.title}>Continue Listening</Typography>
            <Slider {...this.getContinueListeningSlickSettings()}>
              {combinedImages.map((item) => (
                <div key={item.id}>
                  {item.src ? (
                    <div style={webStyles.imgBody}>
                      <img style={webStyles.image} src={item.src} alt={item.alt} />
                      <div style={{ position: "absolute", bottom: "3%", left: "3%", width: '95%' }}>
                        <Typography style={{ ...webStyles.episode }}>
                          {item.episode}
                        </Typography>
                        <Typography style={{ ...webStyles.title }}>
                          {item.name}
                        </Typography>
                        <LinearProgress 
                          sx={{
                            backgroundColor: 'lightgray',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#ff0807',
                            },
                          }} 
                          variant="determinate" 
                          value={item.progress} 
                        />
                      </div>
                    </ div>
                    ) : (
                      <div style={{ width: "100%", height: "100%", backgroundColor: "black" }}></div>
                    )}
                </div>
              ))}
            </Slider>
          </Box>

          <Box sx={{ position: 'relative', height: "430px", width: "100%", paddingTop: "3%"}}>
            <Typography style={webStyles.title}>Sponsored</Typography>
            <Slider {...this.getSponsoredSlickSettings()}>
              {this.state.sponsoredPodcasts.map((item: any) => (
                <div key={item.id} style={webStyles.sponsoredImgBody}>
                  <img style={webStyles.image} src={item.src} alt={item.alt} />
                  <div style={{ position: "absolute", bottom: "17%", left: "1%" }}>
                    <Typography style={{ ...webStyles.episode }}>
                      {item.episode.toUpperCase()}
                    </Typography>
                    <Typography style={{ ...webStyles.title }}>
                      {item.name.toUpperCase()}
                    </Typography>
                    <button style={webStyles.sponsoredButton}>
                      Listen now
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </Box>
          <Box sx={{ position: 'relative', height: "430px", paddingTop: "3%", paddingBottom: "2%"}}>
            <Typography style={webStyles.title}>Suggested For You</Typography>
            <StyledSlider {...this.getSuggestedSlickSettings()}>
              {this.state.suggestedPodcasts.map((item: any) => (
                <div key={item.id} >
                  <div style={webStyles.suggestedImgBody}>
                    <img style={{...webStyles.image, height: "223px"}} src={item.src} alt={item.alt} />
                    {item.selected ? 
                      <img src={selectedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "10px"}} /> :
                      <img src={heart} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "10px"}} />
                    }
                    {item.added ? 
                      <img src={addedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "50px"}} /> :
                      <img src={addBtn} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "50px"}} />
                    }
                    <div style={webStyles.description}>
                      <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                        {item.name.toUpperCase()}
                      </Typography>
                      <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                        {item.artist}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </StyledSlider>
          </Box>
        <Footer navigation={this.props.navigation} id={"1"} />
        </div>
        <div style={{
           paddingLeft: "calc(6% + 135px)"
           }}>
          <AudioPlayerComponent navigation={this.props.navigation} id="" />
        </div>        
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    paddingRight: "5%", 
    paddingLeft: "calc(6% + 135px)", 
    marginBottom: "2%",
    height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
    overflowY: "scroll" as const
  },
  image: {
    objectFit: "cover" as const,
    borderRadius: "8px",
    width: '100%',
    height: typeof window !== 'undefined' && window.innerWidth > 1440 ? "397px" : "370px",
    display: "flex",
    margin: "0 auto",
  },
  imgBody: {
    position: 'relative' as const,
  },
  title: {
    fontWeight: 900,
    fontSize: "30px",
    color: '#fff',
    marginBottom: "10px"
  },
  episode: {
    fontSize: '14px',
    color: "#fff",
    lineHeight: '22px',
    fontWeight: 600
  },
  sponsoredImgBody: {
    width: '100%',
    height: "370px",
    position: 'relative' as const
  },
  sponsoredButton: {
    color: "#fff",
    backgroundColor: "#FF0807",
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 900,
    fontSize: "14px"
  },
  suggestedImgBody: {
    width: '227px',
    height: "223px",
    position: 'relative' as const
  },
  description: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column", 
    height: "76px",
    backgroundColor: "#44403c",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    padding: "16px"
  } as React.CSSProperties,
  
};
const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 40px;
  }
`;
// Customizable Area End
