import React from "react";
import { Box, styled } from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
import Footer from "../../navigationmenu/src/Footer.web"; 

import ChannelLibraryController, {
  Props
} from "./ChannelLibraryController";
import AudioCard from "./components/AudioCard.web";
import AudioPlayerComponent from "./AudioPlayerComponent.web";
const MIN_WINDOW_HEIGHT = 900

export default class ChannelLibrary extends ChannelLibraryController {
  constructor(props: Props) {
    super(props); 
  }

  render() {
    return (
      <>
          <NavigationMenu navigation={this.props.navigation} id='1' />
          <div style={ChannelLibwebStyles.container}>
          <Box sx={{marginLeft:"16rem"}}> 
  
          <CustomTypography data-test-id ={"podcast_following"} sx={{margin:"16px 0px"}}>
            Channel Library
          </CustomTypography>
          <Box display={"flex"} gap={5} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
            {
              this.state.savedEpisode.map((data, index) => (
                <AudioCard 
                key={index} 
                imageUrl={data.imageUrl}
                songName={data.songName}
                artist={data.artist}
                selected={data.selected}
                added={data.added}
                />
              ))
            }
          </Box>
          </Box>
          <Footer navigation={this.props.navigation} id={"1"} />
          </div>   
          <div style={{
           paddingLeft: "calc(6% + 135px)"
           }}>
          <AudioPlayerComponent navigation={this.props.navigation} id="" />
         
        </div>
        </>
    );
  }
}


const CustomTypography = styled(Box)(({ theme }) => ({
  fontWeight: 900,
  fontSize: "30px",
  lineHeight: "40px",
  letter: "-0.5%",
  color: "#fff",
  fontFamily: "Nexa-Heavy"
}));

const ChannelLibwebStyles = {
  container: {
    marginBottom: "2%",
    height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
    overflowY: "scroll" as const
  },
}