// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { handleNavigation } from "../../utilities/src/CustomHelpers";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
}

export interface SS {
  id: any;
}

export default class HostMonetizationOfferingConsultancyController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
    };
  }

  handleMechandiseRoute = (route: any) => {

    if (route.includes("Hosting Paid Webinars or Workshops")) {
      handleNavigation("HostMonetizationPaidWebinar", this.props)
    }
    else if (route.includes("Affiliate Marketing")) {
      handleNavigation("HostMonetizationAffiliateMarketing", this.props)
    }
    else if (route.includes("Offering Consulting or Coaching Services")) {
      handleNavigation("HostMonetizationOfferingConsultancy", this.props)
    }
    else if (route.includes("Selling Digital Products")) {
      handleNavigation("HostMonetizationSellingDigitalProducts", this.props)
    }
    else if (route.includes("Membership/Subscription Model")) {
      handleNavigation("HostMonetizationChooseTemplate", this.props)
    }
    else if (route.includes("Sponsored Content and Partnerships")) {
      handleNavigation("SponsoredContentPartnership", this.props)
    }
    else {
      handleNavigation("HostMonetizationSetup", this.props)
    }
  }

  handleRoutesAffiliateMarketing = (route: any) => {
    if (route.includes("Offering Consulting or Coaching Services")) {
      handleNavigation("HostMonetizationOfferingConsultancy", this.props)
    }
    else if (route.includes("Selling Digital Products")) {
      handleNavigation("HostMonetizationSellingDigitalProducts", this.props)
    }
    else if (route.includes("Membership/Subscription Model")) {
      handleNavigation("HostMonetizationChooseTemplate", this.props)
    }
    else if (route.includes("Sponsored Content and Partnerships")) {
      handleNavigation("SponsoredContentPartnership", this.props)
    }
    else {
      handleNavigation("HostMonetizationSetup", this.props)
    }
  }
  handlePaidWebinarRoute = (route: any) => {
    if (route.includes("Selling Digital Products")) {
      handleNavigation("HostMonetizationSellingDigitalProducts", this.props)
    }
    else if (route.includes("Membership/Subscription Model")) {
      handleNavigation("HostMonetizationChooseTemplate", this.props)
    }
    else if (route.includes("Sponsored Content and Partnerships")) {
      handleNavigation("SponsoredContentPartnership", this.props)
    }
    else {
      handleNavigation("HostMonetizationSetup", this.props)
    }

  }
  handleOfferingConsultancyRoute = (route : any) => {
    if(route.includes("Selling Digital Products")){
              handleNavigation("HostMonetizationSellingDigitalProducts", this.props)
          }        
          else if(route.includes("Membership/Subscription Model")){
              handleNavigation("HostMonetizationChooseTemplate", this.props)
          } 
          else if(route.includes("Sponsored Content and Partnerships")){
              handleNavigation("SponsoredContentPartnership", this.props)
          } 
          else{                                        
              handleNavigation("HostMonetizationSetup", this.props)
          }
  }

}
// Customizable Area End