import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";

export interface Props {
    navigation: any;
    id: string;
}

interface SS {
    id: any;
}
  
interface S {
  channelActiveImg: string | null;
}

export default class BuildController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      channelActiveImg: null
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleChannelClick = (imageId: string) => {
    this.setState({ channelActiveImg: imageId });
  };

  handleChannelManagement = () => {
    handleNavigation("ChannelManagement", this.props)
  }

  handleMediaManagement = () => {
    handleNavigation("MediaManagement", this.props)
  }

}
