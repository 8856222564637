// Customizable Area Start
import React from "react";
import { TextField, IconButton } from "@mui/material";
import { CheckOutlined } from '@mui/icons-material';
import HostSetupRssFeedController, {
    Props,
} from "./HostSetupRssFeedController";
import { rssFeedBackground, not_validated } from "./assets"
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
const MIN_WINDOW_HEIGHT = 900

export default class HostSetupRSSFeed extends HostSetupRssFeedController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <div style={webStyles.container}>
                <div style={styles.mainWrapper}>
                    <div style={styles.title}>Import Existing RSS Feed</div>
                    <div style={styles.container}>
                        <img src={rssFeedBackground} style={{objectFit: "cover", width: "100%"}} />
                        <div style={styles.subContainer}>
                            <div style={{...styles.title, paddingTop: "2%", width: "60%", textTransform: 'capitalize'}}>
                                If you already have a podcast hosted elsewhere:
                                </div>
                            <div style={{...styles.title, fontWeight: 100, paddingTop: "1%", width: "60%", fontSize: "16px", fontFamily: "Nexa-Regular"}}>
                                If you love your current podcast host, you can continue to host your show with them. 
                                Enter your RSS feed below to take advantage of all of the amazing features to build, 
                                engage and monetize your audience.
                            </div>
                            <div style={{...styles.title, fontSize: "16px", fontWeight: 700}}>
                                Welcome to Podcast Town!
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "60%"}}>
                                <div style= {{...styles.title, display: "flex", fontSize: "14px"}}>RSS Feed link</div>
                                <TextField
                                    value={this.state.link}
                                    name="first_name"
                                    data-test-id="changeField"
                                    onChange={this.handleChange}
                                    sx={{
                                        width: "100%",
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: "black",
                                            "& fieldset": {
                                                borderColor: "#CBD5E1",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#CBD5E1",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#CBD5E1",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: "#CBD5E1",
                                        },
                                        "& .MuiInputBase-input": {
                                            color: "white",
                                            fontWeight: 900,
                                            backgroundColor: "black",
                                            "&:-webkit-autofill": {
                                              backgroundColor: "black !important", 
                                              WebkitBoxShadow: "0 0 0 1000px black inset", 
                                              WebkitTextFillColor: "#CBD5E1 !important" 
                                            }
                                        },
                                    }}
                                />
                                <div style={{display: "flex", alignItems: "center", paddingTop: "1%"}} >
                                    {this.state.isValidated === false && (
                                        <>
                                            <img src={not_validated} width={24} height={24} />
                                            <div style={{ fontWeight: 700, fontSize: "12px", color: "#FF0807", fontFamily: "Nexa-Heavy" }}>
                                                RSS Feed is Not Validated
                                            </div>
                                        </>
                                    )}
                                    {this.state.isValidated === true && (
                                        <>
                                            <IconButton><CheckOutlined style={{color: "#34D399"}}/></IconButton>
                                            <div style={{ fontWeight: 700, fontSize: "12px", color: "#34D399", fontFamily: "Nexa-Heavy" }}>
                                                RSS Feed is Validated
                                            </div>
                                        </>
                                    )}
                                </div>
                                <button
                                    onClick={this.handleContinue}
                                    style={{
                                        ...styles.button,
                                        backgroundColor: (ResolveCondition((this.state.link === '' || this.state.link.trim() === '' || this.state.isValidated === null), '#B2B3B3', '#FF0807'))
                                    }}  
                                    data-test-id="continueBtn"
                                    disabled={(this.state.link === '' || this.state.link.trim() === '' || this.state.isValidated === null) ? true : false}
                                >
                                    <b>Continue</b>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
            <Footer navigation={this.props.navigation} id={"1"} />
            </div>
            </>
            
        );
    }

}
const styles = {
    mainWrapper: {
        margin: "0 auto",
        height: "auto",
        paddingRight: "5%",
        paddingLeft: "calc(6% + 135px)",
    },
    container: {
        color : "white",
        width: "100%",
        background:"#44403C",
        paddingBottom : "3%",
    },
    title: {
        color: 'white', 
        paddingBottom: "1%",
        fontWeight: 900, 
        fontSize: "30px",
        fontFamily: "Nexa-Heavy"
    },
    subContainer: {
        width:"100%",
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center" as const,
    },
    button: {
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
        height: "4%",
        width: "40%",
        color: "white",
        borderRadius: "8px",
        fontFamily: "Nexa-Heavy",
        fontSize: "18px",
        fontWeight: 900,
        textTransform: "capitalize" as const,
        marginTop: "4%",
        border: "none",
        textAlign: "center" as const,
        padding: "16px"
    }
}
const webStyles = {
    container: {
        height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
        overflowY: "scroll" as const,
        scrollbarWidth: "thin",
        scrollbarColor: "red gray",
        "&::-webkit-scrollbar": {
            width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "red",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "gray",
        },
        "&::-webkit-scrollbar-button": {
            display: "none", 
          },
    } as const
}
// Customizable Area End
