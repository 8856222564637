import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { logo } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  totalStars: number;
  reviewCount: number;
  fullStars: number;
  hasHalfStar: boolean;
  emptyStars: number;
  totalReviews: number;
  podcastName: string;
  followButtonText: string;
  selectedTab: string;
  openEditLogoModal: boolean;
  selectedFile: File | null;
  imagePreviewUrl: string;
  tempLogoUrl: string;
  logo: File | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostCatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      totalStars: 5,
      reviewCount: 3.5,
      fullStars: 0,
      hasHalfStar: false,
      emptyStars: 0,
      totalReviews: 1.5,
      podcastName: "Cost of Winning",
      followButtonText: "Follow",
      selectedTab: "Episodes",
      openEditLogoModal: false,
      selectedFile: null,
      imagePreviewUrl: "",
      tempLogoUrl: "",
      logo: logo
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.calculateStars(this.state.reviewCount);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  calculateStars = (reviewCount: number) => {
    const fullStars = Math.floor(reviewCount);
    const hasHalfStar = reviewCount % 1 !== 0 ? true : false;
    const emptyStars = this.state.totalStars - fullStars - (hasHalfStar ? 1 : 0)
    this.setState({ fullStars, hasHalfStar, emptyStars });
  }
  handleFollowButton = () => {
    if (this.state.followButtonText === "Follow") {
      this.setState({ followButtonText: "Followed" });
    }
    else if (this.state.followButtonText === "Followed") {
      this.setState({ followButtonText: "Follow" });
    }
  }
  handleTabChanges = (tab: string) => {
    const newTab = tab;
    this.setState({ selectedTab: newTab });
  };
  openEditLogoModal = () => {
    this.setState({ openEditLogoModal: true, tempLogoUrl: "" })
  };
  handleCloseEditLogoDialog = () => {
    this.setState({ openEditLogoModal: false })
  }
  handleDoneClick = () => {
    let imageUrl = this.state.selectedFile ? URL.createObjectURL(this.state.selectedFile) : '';
    this.setState({ imagePreviewUrl: imageUrl, openEditLogoModal: false, logo: this.state.selectedFile });
  }
  handleCancelClick = () => {
    this.setState({ selectedFile: null, imagePreviewUrl: "", tempLogoUrl: "" })
    this.setState({ openEditLogoModal: false });
  }
  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      this.setState({ selectedFile: file, tempLogoUrl: imageUrl });
    };
  };
  // Customizable Area End
}
