import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { carouselNext, carouselPrev, headPhone, playBtn, podcastImage1, podcastImage2, suggetedImg } from "./assets";
import { AppContext } from "./context/AppContext";
import React from "react";
export type MediaList = Media[]

export interface Media {
  itmid: string
  type: string
  attributes: { file_info: FileInfo[] }
}

export interface FileInfo {
  title: string
  description: string
  focus_areas: string[]
  urldoc: string
  content_type: string
  text_file_to_str: string
  file_content: string
}

interface Song {
  songTitle: string;
  songSubTitle: string;
  image: string; 
}

interface Playlist {
  bgcolor: string;
  playIcon: string; 
  title: string;
  playLists: Song[];
}

interface PodcastList {
  imageUrl: string;
  songName: string;
  artist: string;
  following: string
}

interface SavedEpisode {
  imageUrl: string;
  selected: string;
  added: string;
  songName: string;
  artist: string;
}
interface ArrowProps {
  onClick: () => void;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  mediaList: MediaList;
  header: string;
  loading: boolean;
  documentModal: boolean;
  docUrl: string;
  videoModal: boolean;
  videoUrl: string;
  media: {
    nowPlaying: string;
    duration: number;
    progress: number;
  };
  mediaType: string;
  pageNo: number;
  limit: number;
  totalCount: number;
  currentPageNo: number;
  audioList: MediaList;
  continueListen: Playlist[];
  followingPodCasts: PodcastList[];
  savedEpisode: SavedEpisode[];
  channelLibrary: SavedEpisode[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

export default class AudioLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    // Customizable Area End
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      mediaList: [],
      audioList: [],
      header: "Lets listen to some audios to make yourself feel better.",
      loading: false,
      documentModal: false,
      docUrl: "",
      videoModal: false,
      videoUrl: "",
      media: {
        nowPlaying: "",
        duration: 0,
        progress: 0
      },
      mediaType: 'audio',
      pageNo: 0,
      limit: 7,
      totalCount: 0,
      currentPageNo: 0,
      continueListen: [
        {
          bgcolor: "red",
          playIcon: playBtn,
          title: "Main playList",
          playLists: [
            { songTitle: "The True", songSubTitle: "Cost of success Ep.9", image: podcastImage1 },
            { songTitle: "This American Life", songSubTitle: "Love your era Ep.8", image: playBtn },
            { songTitle: "Scam Goddess", songSubTitle: "Catalina appears Ep.6", image: playBtn},
          ],
        },
        {
          bgcolor: "white",
          playIcon: headPhone,
          title: "Saved Episodes",
          playLists: [
            { songTitle: "Ep 3 - Its Jhonnie" , songSubTitle: "The Edge of Sleep", image: playBtn },
            { songTitle: "Ep 3 - Oh captain my captain" , songSubTitle: "Philosophize This!", image: playBtn},
            { songTitle: "Ep 12 - Its Britney B*'tch!" , songSubTitle: "Toxic Britney Spears story", image: playBtn},
          ],
        },
      ],
      followingPodCasts : [
        {
          imageUrl: podcastImage1, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage1, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage2, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        },
        {
          imageUrl: podcastImage1, 
          songName: 'TOXIC',
          artist: "Richard Donner",
          following: "following"
        }
      ],
      savedEpisode: [
        {
          imageUrl: podcastImage1,
          added: "not-added",
          songName: "EP-7 LOOK AT ME",
          selected: "selected",
          artist: "Tweenty thousand heartz"
        },
        {
          imageUrl: podcastImage2,
          added: "added",
          selected: "not selected",
          songName: "EP-3 FUTURMA",
          artist: "I AM RAMA"
        },
        {
          imageUrl: podcastImage1,
          added: "",
          selected: "not selected",
          songName: "What is love",
          artist: "Modern love"
        },
        {
          imageUrl: suggetedImg,
          selected: "not selected",
          artist: "Scam Goddess",
          added: "not added",
          songName: "Baby don't hurt me",
        },
        {
          imageUrl: podcastImage1,
          selected: "selected",
          songName: "No more",
          added: "",
          artist: "Edge of sleep"
        },
        {
          selected: "selected",
          imageUrl: suggetedImg,
          added: "not added",
          artist: "Milton Johnson",
          songName: "Aristotles",
        },
      ],
      channelLibrary: [
        {
          selected: "",
          imageUrl: suggetedImg,
          added: "not-added",
          songName: "BUZZ CAST",
          artist: "Buzzprout"
        },
        {
          selected: "selected",
          imageUrl: podcastImage2,
          added: "added",
          artist: "Jhoanne Slate",
          songName: "SPECTACULAR VERNACULAR",
        },
        {
          selected: "selected",
          imageUrl: podcastImage1,
          added: "",
          songName: "The Atlas obscura",
          artist: "Instrumento"
        },
        {
          selected: "not selected",
          imageUrl: suggetedImg,
          songName: "All consuming",
          added: "not added",
          artist: "Dr. Axel Foley"
        },
        {
          selected: "selected",
          imageUrl: podcastImage1,
          songName: "99% invisible",
          added: "",
          artist: "Jockie Robinson"
        },
        {
          artist: "Kev fed",
          imageUrl: podcastImage2,
          selected: "selected",
          added: "not added",
          songName: "Toxic",
        },
      ]
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      if (apiRequestCallId === this.getMediaApiCallId) {
        if (responseJson && responseJson?.data) {
          this.setState(prev => ({
            totalCount: responseJson?.data[0].attributes?.count,
            audioList: responseJson?.data,
            mediaList: (prev.pageNo === 0) ?
              responseJson?.data :
              [...this.state.mediaList, ...responseJson?.data] || [],
            loading: false
          }));
        }
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType? = AppContext;

  handleSavedEpisode = () => {
    this.navigateTo("SavedEpisode")
  }

  handlePodCastFollowing = () => {
    this.navigateTo("PodCastFollowing")
  }

  handleChannelLibrary = () => {
    this.navigateTo("ChannelLibrary")
  }

  navigateTo(screen:string){
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    this.send(message);
  }

  getSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: React.createElement(this.nextSuggestedArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSuggestedArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 4,
              dots: true
            }
          },
          {
            breakpoint: 1700, 
            settings: {
              slidesToShow: 5, 
              slidesToScroll: 4,
              dots: true
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
        ]
       }
    )
  }

  nextSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
        src={carouselNext} 
        onClick={onClick}
        style={{
          position: 'absolute',
          right: innerWidth > 1440 ? "-7.5%" : "-8.5%",
          top: innerWidth > 1440 ? "45%" : "50%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1
        }} 
        alt="Next Arrow"
      />
    )
  }

  prevSuggestedArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        src={carouselPrev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-4.5%" : "-5.5%",
          top: innerWidth > 1440 ? "45%" : "40%",
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }


  async componentDidMount(): Promise<void> {

    this.getMedia();


    const msag: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msag);
  }

  handlePagination = (event: Object, value: number) => {
    this.setState({ currentPageNo: value });
    this.getMedia(value);
  }

  networkRequest = ({
    endPoint,
    method,
    headers,
    body,
    newState
  }: {
    endPoint: string;
    headers?: Record<string, string>;
    body?: Record<string, string>;
    method: "GET" | "POST" | "PUT" | "DELETE";
    newState?: Partial<S>;
  }) => {
    if (typeof newState === "object") {
      this.setState(prev => ({ ...prev, ...newState }));
    }
    const defaultHeaders = {
      'Content-Type': configJSON.exampleApiContentType,
      token: (this.context as any).state?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers || defaultHeaders)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getMedia = (currentPageNo?: number) => {
    let strBody = {
      limit: `${this.state.limit}`,
      page: `${currentPageNo || this.state.pageNo}`
    }
    let urlParams = new URLSearchParams(strBody).toString();
    this.getMediaApiCallId = this.networkRequest({
      endPoint: `audio_list?${urlParams}`,
      method: "POST",
      newState: { loading: false }
    });
  }

  fetchMoreData = () => {
    if (
      (this.state.pageNo >= this.state.totalCount) ||
      this.state.loading
    ) {
      return;
    }
    this.setState(prevState => ({
      pageNo: prevState.pageNo + 1
    }), () => this.getMedia());
  }

  goBackcalling = () => {
    this.props.navigation.goBack()
  }

  // Customizable Area End
}
