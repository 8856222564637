// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { handleNavigation } from "../../utilities/src/CustomHelpers";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  isModalOpen : boolean;
}

export interface SS {
  id: any;
}

export default class HostCrowfundingResidentSupportController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isModalOpen : false
    };
  }

  handleRoutesResident = (route:string, type:string) => {
    const onlineAdvertising = "Online Advertising";
    const merchandiseSales = "Merchandise Sales";
    const paidWebinars = "Hosting Paid Webinars or Workshops";
    const affiliateMarketing = "Affiliate Marketing";
    const consultingServices = "Offering Consulting or Coaching Services";
    const digitalProducts = "Selling Digital Products";
    const membershipModel = "Membership/Subscription Model";
    const sponsoredContent = "Sponsored Content and Partnerships";
  
    if (route.includes(onlineAdvertising)) {
      handleNavigation("HostMonetizationOnlineAdvertising", this.props);
    } else if (route.includes(merchandiseSales)) {
      handleNavigation("HostMonetizationMerchandise", this.props);
    } else if (route.includes(paidWebinars)) {
      handleNavigation("HostMonetizationPaidWebinar", this.props);
    } else if (route.includes(affiliateMarketing)) {
      handleNavigation("HostMonetizationAffiliateMarketing", this.props);
    } else if (route.includes(consultingServices)) {
      handleNavigation("HostMonetizationOfferingConsultancy", this.props);
    } else if (route.includes(digitalProducts)) {
      handleNavigation("HostMonetizationSellingDigitalProducts", this.props);
    } else if (route.includes(membershipModel)) {
      handleNavigation("HostMonetizationChooseTemplate", this.props);
    } else if (route.includes(sponsoredContent)) {
      handleNavigation("SponsoredContentPartnership", this.props);
    } else {
      handleNavigation("HostMonetizationSetup", this.props);
    }
  };
  
  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
};

}
// Customizable Area End