// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface Data
  {
    attributes: {
        name: string,
        image: any
    }
}
export interface S {
  data : any;
  monetizationCategories : string[];  
}

export interface SS {
  id: any;
}

export default class HostMonetizationSetupController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  memoizationCategoriesApiCallId = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
   data : [],
   monetizationCategories : []
    };
  }

handleItemClick = (item:any) => {
  const { monetizationCategories } = this.state;
  const itemName = item?.attributes?.name;

  if (Array.isArray(monetizationCategories) && monetizationCategories.includes(itemName)) {
      this.setState({
          monetizationCategories: monetizationCategories.filter(
              (category) => category !== itemName
          ),
      });
  } else {
      this.setState({
          monetizationCategories: Array.isArray(monetizationCategories)
              ? [...monetizationCategories, itemName]
              : [itemName],
      });
  }
};


  async receive(from: string, message: Message) {
    if (this.memoizationCategoriesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForFetchSubsApi(message);
    }
  }

  async componentDidMount() {
    let data = await getStorageData("monetizationCategories",true)
    if(data) {

      this.setState({
        monetizationCategories : data
      })
    }
    this.memoizationCategories()
  }

  memoizationCategories =async () => {     
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
       
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.memoizationCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.monetizationCategories
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForFetchSubsApi = async (message: Message) => {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseJson.data) {
      this.setState({ data: responseJson.data });
    }
  };

handleDataNavigations = () => {
  setStorageData("monetizationCategories", JSON.stringify(this.state.monetizationCategories));

  const categories = this.state.monetizationCategories;
  const navigationMap : { [key: string]: string } = {
      "Sponsored Content and Partnership": "SponsoredContentPartnership",
      "Membership/Subscription Model": "HostMonetizationChooseTemplate",
      "Selling Digital Products": "HostMonetizationSellingDigitalProducts",
      "Offering Consulting or Coaching Services": "HostMonetizationOfferingConsultancy",
      "Affiliate Marketing": "HostMonetizationAffiliateMarketing",
      "Hosting Paid Webinars or Workshops": "HostMonetizationPaidWebinar",
      "Merchandise Sales": "HostMonetizationMerchandise",
      "Online Advertising": "HostMonetizationOnlineAdvertising",
      "Crowdfunding or Resident Support": "CrowdfundingOrResidentSupport"
  };
 

  if (categories.length > 0) {
    const keys = Object.keys(navigationMap);
    const sortedCategories = categories.slice().sort((a, b) => keys.indexOf(a) - keys.indexOf(b));

    let navigated = false;
    for (let category of sortedCategories) {
      const navigationRoute = navigationMap[category];
      if (navigationRoute && !navigated) {
        handleNavigation(navigationRoute, this.props);
        navigated = true;
      }
    }
  }}

}
// Customizable Area End
