import React from "react";
// Customizable Area Start
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
  TextField,
  Stack,
  IconButton,
  styled,
  Box,
  Checkbox,
  InputAdornment,
  Dialog,
  DialogContent,
  Divider,
  DialogActions
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import { MailOutlined, Reply, DeleteOutlineRounded, Search, CloseOutlined } from '@mui/icons-material';
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { goBack } from "./assets";
// Customizable Area End
import MyInboxController, { Props } from "./MyInboxController";
const MIN_WINDOW_HEIGHT = 900
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class MyInbox extends MyInboxController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />
        <div style={MyInboxwebStyles.container}>
        <StyledWrapperSetting>
        <Box display="flex" alignItems="center" mb={3}>
          <img onClick={this.handleGoBack} src={goBack} alt="Go Back" style={{ marginBottom: '20px', width: "2%" }} />
          <Typography variant="h4" sx={{ color: '#fff', marginBottom: '15px', marginLeft: "15px", fontWeight: "bold" }}>
            My Inbox
          </Typography>
        </Box>
          <Box className= "main-container">
            <TableContainer>
              <Box display="flex" alignItems="center">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: '#A5A1C6' }} />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '98px',
                      color: '#fff',
                      width: '545px',
                      height: "44px",
                      marginBottom: '7%',
                      border: "1px solid white",
                      marginLeft: "13%"
                    }
                  }}
                />
                {this.state.selected.length > 0 && (<Stack direction="row" spacing={2} sx={{ marginBottom: '36px', marginLeft: "8%" }}>
                  <Button variant="contained" startIcon={<MailOutlined />} sx={{ backgroundColor: '#030303', width: "165px", height: "35px", borderRadius: "8px", padding: "16px", marginLeft: "20px", textTransform: "capitalize", '&:hover': {backgroundColor: "#030303"}, fontFamily: "Nexa-Regular", fontWeight: "bold" }} data-test-id= "mark-as-read-button">Mark as Read</Button>
                  <Button variant="contained" startIcon={<Reply />} sx={{ backgroundColor: '#030303', width: "111px", height: "35px", borderRadius: "8px", padding: "16px", marginLeft: "20px", textTransform: "capitalize", '&:hover': {backgroundColor: "#030303"}, '&.Mui-disabled': {backgroundColor: "#B2B3B3", color: "#fff"}, fontFamily: "Nexa-Regular", fontWeight: "bold" }} onClick={() => this.handleReplyClick(this.state.selected[0])} disabled={this.state.selected.length > 1 ? true : false} data-test-id= "reply-button">Reply</Button>
                  <Button variant="contained" startIcon={<DeleteOutlineRounded />} sx={{ backgroundColor: '#FF0807', width: "111px", height: "35px", borderRadius: "8px", padding: "16px", marginLeft: "20px", textTransform: "capitalize", '&:hover': {backgroundColor: "#FF0807"}, fontFamily: "Nexa-Regular", fontWeight: "bold" }} onClick={this.handleOpenDialog} data-test-id= "delete-button">Delete</Button>
                </Stack>
                )}
                <Typography style={{fontFamily:"Nexa-Heavy", color: "#fff", marginLeft: this.state.selected.length > 0 ? "8%" : "45%", marginTop: "-3%"}}> &lt; 1-20 from 200 &gt; </Typography>
              </Box>
              <Table sx={{ backgroundColor: '#44403C', borderRadius: 2, width: "92%", marginLeft: "5%" }}>
                <TableBody>
                  {this.state.message.map((message, index) => {
                    const isItemSelected = this.state.selected.includes(message.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                      <TableRow
                        key={message.id}
                        data-test-id={message.id}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        sx={{
                          cursor: 'pointer', height: "109px", backgroundColor: (isItemSelected ? 'red' : ((index + 1) % 2 === 0 ? "#312F2D" : "#44403C")),
                          '&.Mui-selected': {
                            backgroundColor: "red",
                            '&:hover': {
                              backgroundColor: 'red', 
                            },
                          },
                          '&:hover': {
                            backgroundColor: (isItemSelected ? 'red' : ((index + 1) % 2 === 0 ? "#312F2D" : "#44403C")), // Define hover behavior for non-selected items
                          },
                          paddingnBottom: "2%",
                          clipPath: "xywh(0 0 100% 100% round 1.5em)"
                        }}
                      >
                        <TableCell padding="checkbox" sx={{ borderBottom: "0px"}}>
                          <Checkbox
                            checked={isItemSelected}
                            data-test-id= "checkbox"
                            onClick={(event) => this.handleClick(event, message.id)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            sx={{
                              borderRadius: "6px",
                              color: '#FFFFFF',
                              "&.Mui-checked": {
                                color: "#030303"
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ borderBottom: "0px"}}>
                          <IconButton sx={{ color: '#fff' }} onClick={() => this.handleReplyClick(message.id)} data-test-id= "reply-icon">
                            <Reply />
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ borderBottom: "0px"}} onClick={this.handleOpenDialog} data-test-id= "delete-icon">
                          <IconButton sx={{ color: '#fff' }}>
                            <DeleteOutlineRounded />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{ overflow: "hidden", color: '#fff', borderBottom: "0px", fontFamily: "Nexa-Heavy", textTransform: "uppercase" }}
                        >
                          {message.sender}
                        </TableCell>
                        <TableCell sx={{ color: '#fff', overflow: "hidden", borderBottom: "0px", fontFamily: "Nexa-Regular", fontWeight: "bold" }}>{message.subject}</TableCell>
                        <TableCell sx={{ color: '#bbb', overflow: "hidden", borderBottom: "0px", fontFamily: "Nexa-Regular", fontWeight: "bold" }}>{message.snippet}</TableCell>
                        <TableCell sx={{
                          color: '#bbb', overflow: "hidden", borderBottom: "0px", fontFamily: "Nexa-Regular", fontWeight: "bold" }}>{message.time}</TableCell>
                      </TableRow>
                      <TableRow sx={{ height: "8px" }}>
                        <TableCell colSpan={7} sx={{ padding: 0, borderBottom: "0px" }} />
                      </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </StyledWrapperSetting>
        <Footer navigation={this.props.navigation} id={"1"} />
        </div>
        <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} PaperProps={{
          style: {
            margin: '0 auto',
            borderBottomRightRadius: '30px',
            width: '27%',
            height: '27%'
          },
        }}>
            <IconButton 
              style={{ 
                position: 'absolute', 
                top: 8, 
                right: 8, 
                color: '#fff' 
              }}
              data-test-id= "first-dialog-close-icon"
              onClick={this.handleCloseDialog} 
            >
              <CloseOutlined />
            </IconButton>
          <DialogContent sx={{ backgroundColor: "#44403C", textAlign: "center" }}>
            <Typography align="center" variant="h6"  style={{ color: "#fff", marginTop: "2%", fontFamily: 'Nexa-Heavy' }} gutterBottom>
                Are you sure you want to delete this message?
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'right', backgroundColor: "#44403C", height: '18%', padding: "5%" }}>
            <Button onClick={this.handleCloseDialog} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="no">
              No
            </Button>
            <Button onClick={this.handleCloseDialog} variant="contained" style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', marginRight: "2%", width: "120px", height: "45px" }} data-test-id="yes">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  paddingLeft: "calc(6% + 135px)",
  paddingRight: "5%",
  height: "auto",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .main-container": {
    backgroundColor: "#44403C", 
    padding: '20px', 
    height: "auto", 
    marginTop: "2%", 
    borderRadius: "8px", 
    width: "97%" 
  }
});
const MyInboxwebStyles = {
  container: {
    marginBottom: "2%",
    height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
    overflowY: "scroll" as const
  },
}
// Customizable Area End
