// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Typography,
  Grid, Button, TextField, FormControl, Select, MenuItem
} from "@mui/material";
import SetupPodcastProfileControllerNoRssFeed, {
  Props,
} from "./SetupPodcastProfileControllerNoRssFeed";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { upload } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
const MIN_WINDOW_HEIGHT = 900


export default class SetupPodcastProfileNoRssFeed extends SetupPodcastProfileControllerNoRssFeed {
  constructor(props: Props) {
    super(props);
  }
  validationSchema = Yup.object().shape({
    podcastCover: Yup.string().required('Required'),
    podcastName: Yup.string().required('Required'),
    hostFirstName: Yup.string().required('Required'),
    hostLastName: Yup.string().required('Required'),
    hostEmail: Yup.string().email('Invalid email address').required('Required'),
    hostLocation: Yup.string().required('Required'),
    podcastSubCategory: Yup.string().required('Required'),
    podcastCategory: Yup.string().required('Required')
  });
  render() {
    return (
      <>
        <NavigationMenu navigation={this.props.navigation} id='1' />
        <div style={webStyles.container}>
          <StyleWrapperSetupPodcastProfile>
            <Box className="mainTitle">Setup Podcast Profile</Box>
            <Box >
              <Box className="redContainer"></Box>
              <Grid className="container">
                <Grid className="subContainer">
                  <Formik
                    initialValues={{
                      podcastCover: '',
                      podcastName: '',
                      description: '',
                      hostFirstName: '',
                      hostLastName: '',
                      hostEmail: '',
                      hostLocation: '',
                      podcastLocation: '',
                      podcastCategory: '',
                      podcastSubCategory: ''
                    }}
                    validationSchema={this.validationSchema}
                    validateOnMount={true}
                    onSubmit={(values) => {
                      this.handleSubmit(values)
                    }}
                  >
                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                      <Form onSubmit={handleSubmit}>
                        <Grid className="fields">
                          <Box>
                            <label className="label">Upload Podcast Cover<span>*</span></label>
                          </Box>
                          <Box style={{marginTop: "2%"}}>
                            <label style={{ fontFamily: "Nexa-Regular", color: "#fff" }}>(Image size must be 3000x3000)</label>
                          </Box>
                          <Box
                            data-test-id="drop"
                            onDrop={(e) => { this.handleDrop(e, setFieldValue) }}
                            onDragOver={(e: any) => { e.preventDefault() }}
                            sx={{
                              margin: "auto",
                              marginTop: "2%",
                              marginBottom: "2%",
                              width: ResolveCondition((this.state.imageUrl !== "" && this.state.invalidFileMessage === ""), "480px", "956px"),
                              height: "247px",
                              border: ResolveCondition((this.state.invalidFileMessage === ""), '2px dashed #ccc', '2px dashed #FF0807'),
                              borderRadius: '10px',
                              padding: '20px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              backgroundColor: 'transparent',
                              backgroundImage: this.state.imageUrl ? `url(${this.state.imageUrl})` : 'none',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              style={{ display: 'none' }}
                              id="file-input"
                              data-test-id="selectFile"
                              onChange={(e) => { this.handleFileSelect(e, setFieldValue) }}
                            />
                            <label htmlFor="file-input">
                              <Box sx={{ marginTop: "5%", lineHeight: 2 }}>
                                <img src={upload} alt="upload" />
                                <Box className="title">
                                  Drag & Drop your file here <br />OR
                                </Box>
                                <Button className="btnBrowse" component="span">
                                  Browse file
                                </Button>
                              </Box>
                            </label>
                          </Box>
                          {this.state.invalidFileMessage !== "" && (
                            <Typography variant="body2" style={{ color: "red", fontFamily: 'Nexa-Heavy', width: "80%", marginBottom: "2%" }}>
                              {this.state.invalidFileMessage}
                            </Typography>
                          )}

                          <Box>
                            <label className="label">Podcast Name<span>*</span></label>
                          </Box>
                          <TextField
                            id="outlined-basic"
                            name="podcastName"
                            value={values.podcastName}
                            onChange={handleChange}
                            sx={{
                              width: "954px",
                              marginTop: "2%",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#CBD5E1",
                              },
                              "& .MuiInputBase-input": {
                                color: "#CBD5E1",
                                backgroundColor: "black",
                                "&:-webkit-autofill": {
                                  backgroundColor: "black !important",
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#CBD5E1 !important",
                                },
                              },
                            }}
                          />
                          <>{console.log("Error-->", errors)}</>

                          <Box style={{ marginTop: "3%" }}>
                            <label className="label">Description</label>
                          </Box>
                          <TextField
                            id="outlined-basic"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            multiline
                            sx={{
                              width: "100%",
                              height: "177px",
                              marginTop: "2%",
                              "& .MuiOutlinedInput-root": {
                                height: "177px",
                                backgroundColor: "black",
                                boxSizing: "border-box",
                                alignItems: "flex-start",
                                padding: "0",
                                "& fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                              },
                              "& .MuiInputBase-root": {
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                              },
                              "& .MuiInputBase-input": {
                                color: "#CBD5E1",
                                padding: "10px",
                                lineHeight: "1.5",
                                height: "100%",
                                boxSizing: "border-box",
                                maxHeight: "177px",
                                overflowY: "auto",
                                display: "block",
                                "&:-webkit-autofill": {
                                  backgroundColor: "black !important",
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#CBD5E1 !important",
                                },
                              },
                            }}
                          />


                          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                            <Box sx={{ width: "48%", marginTop: "3%" }}>
                              <label className="label">Host First Name<span>*</span></label>
                              <TextField
                                id="outlined-basic"
                                name="hostFirstName"
                                value={values.hostFirstName}
                                onChange={handleChange}
                                sx={{
                                  marginTop: "2%",
                                  width: "100%",
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiInputBase-input": {
                                    backgroundColor: "black",
                                    color: "#CBD5E1",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                    },
                                  },
                                }}
                              />
                            </Box>

                            <Box sx={{
                              width: "48%",
                              marginTop: "3%"
                            }}>
                              <label className="label">Host Last Name<span>*</span></label>
                              <TextField
                                id="outlined-basic"
                                name="hostLastName"
                                value={values.hostLastName}
                                onChange={handleChange}
                                sx={{
                                  width: "100%",
                                  marginTop: "2%",
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    height: "56px",
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "black",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                }}
                              />
                            </Box>
                          </Box>

                          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                            <Box sx={{ width: "48%", marginTop: "3%" }}>
                              <label className="label">Host Email Address<span>*</span></label>
                              <TextField
                                id="outlined-basic"
                                name="hostEmail"
                                value={values.hostEmail}
                                onChange={handleChange}
                                sx={{
                                  width: "100%",
                                  marginTop: "2%",
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "black",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                    },
                                  },
                                }}
                              />
                            </Box>

                            <Box sx={{ width: "48%", marginTop: "3%" }}>
                              <label className="label">Host Location<span>*</span></label>
                              <TextField
                                name="hostLocation"
                                value={values.hostLocation}
                                onChange={handleChange}
                                id="outlined-basic"
                                sx={{
                                  width: "100%",
                                  marginTop: "2%",
                                  "& .MuiOutlinedInput-root": {
                                    height: "56px",
                                    "& fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#CBD5E1",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    color: "#CBD5E1",
                                    backgroundColor: "black",
                                    "&:-webkit-autofill": {
                                      backgroundColor: "black !important",
                                      WebkitTextFillColor: "#CBD5E1 !important",
                                      WebkitBoxShadow: "0 0 0 1000px black inset",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#CBD5E1",
                                  },
                                }}
                              />
                            </Box>
                          </Box>

                          <Box style={{ marginTop: "3%" }}>
                            <label className="label">Location of Podcast</label>
                          </Box>
                          <TextField
                            id="outlined-basic"
                            name="podcastLocation"
                            value={values.podcastLocation}
                            onChange={handleChange}
                            sx={{
                              width: "954px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#CBD5E1",
                                },
                              },
                              marginTop: "2%",
                              "& .MuiInputLabel-root": {
                                color: "#CBD5E1",
                              },
                              "& .MuiInputBase-input": {
                                color: "#CBD5E1",
                                backgroundColor: "black",
                                "&:-webkit-autofill": {
                                  backgroundColor: "black !important",
                                  WebkitBoxShadow: "0 0 0 1000px black inset",
                                  WebkitTextFillColor: "#CBD5E1 !important",
                                },
                              },
                            }}
                          />
                          <Grid className="fields">
                            <Grid container spacing={2} sx={{ marginTop: "4%" }}>
                              <Grid item xs={6}>
                                <Box sx={{ marginBottom: "8px" }}>
                                  <Typography variant="body2" className="label">Podcast Category*</Typography>
                                </Box>
                                <FormControl fullWidth variant="outlined"

                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#FFFFFF",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#FFFFFF",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#FFFFFF",
                                      },
                                      "& .MuiSelect-select": {
                                        color: "#FFFFFF",
                                      },
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "transparent",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      color: "#FFFFFF",
                                    },
                                  }}>
                                  <Select
                                    name="category"
                                    onChange={(event) => {
                                      const selectedCategory = this.state.categoryList.find(
                                        (item) => item.attributes.name === event.target.value
                                      );
                                      if (selectedCategory && selectedCategory.id) {
                                        setFieldValue('podcastCategory', selectedCategory.id);
                                        this.subCategoryApi();
                                      }
                                      this.setState({ selectedCategory });
                                    }}

                                    data-test-id="category"
                                    displayEmpty
                                    defaultValue=""
                                    IconComponent={(props) => (
                                      <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                    )}
                                    MenuProps={{
                                      PaperProps: {
                                        sx: {
                                          overflowY: 'auto',
                                          maxHeight: 250,
                                          backgroundColor: 'black',
                                          "& .Mui-selected": {
                                            backgroundColor: "#333333 !important",
                                          },
                                          "& .MuiMenuItem-root": {
                                            color: "#FFFFFF",
                                          },

                                        },
                                      },
                                    }}
                                    sx={{ backgroundColor: 'black', color: "#FFFFFF" }}
                                  >
                                    {
                                      this.state.categoryList && this.state.categoryList.map((item) => (

                                        <MenuItem value={item.attributes.name} key={item.id} >{item.attributes.name}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginBottom: "8px" }}>
                                  <Typography variant="body2" className="label">Podcast Sub-Category*</Typography>
                                </Box>
                                <FormControl fullWidth variant="outlined" sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#FFFFFF",

                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "& .MuiSelect-select": {
                                      color: "#FFFFFF",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "transparent",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: "#FFFFFF",
                                  },
                                }}>
                                  <Select
                                    name="subCategory"
                                    onChange={(e) => {
                                      const selectedSubCategory = this.state.subCategoryList.find(
                                        (item) => item.attributes.name === e.target.value
                                      )
                                      if (selectedSubCategory && selectedSubCategory.id) {
                                        setFieldValue('podcastSubCategory', selectedSubCategory.id)
                                      }
                                    }
                                    }
                                    displayEmpty
                                    defaultValue=""
                                    data-test-id="subCategory"
                                    MenuProps={{
                                      PaperProps: {
                                        sx: {
                                          maxHeight: 250,
                                          overflowY: 'auto',
                                          backgroundColor: 'black',
                                          "& .MuiMenuItem-root": {
                                            color: "#FFFFFF",
                                          },
                                          "& .Mui-selected": {
                                            backgroundColor: "#333333 !important",
                                          },
                                        },
                                      },
                                    }}
                                    sx={{ color: "#FFFFFF", backgroundColor: 'black', }}
                                    IconComponent={(props) => (
                                      <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                    )}
                                  >
                                    {
                                      this.state.subCategoryList && this.state.subCategoryList.map((item) => (

                                        <MenuItem value={item.attributes.name} key={item.id}>{item.attributes.name}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                              </Grid>

                            </Grid>
                          </Grid>

                          <Grid className="fields">
                            <Grid item xs={6}>
                              <Button
                                style={{ color: "white", textTransform: "capitalize" }}
                                data-test-id="submit-btn"
                                className={ResolveCondition(Object.keys(errors || {}).length > 0 || this.state.invalidFileMessage !== "", "btnSignUp", "btnSelectedSignUp")}
                                disabled={Object.keys(errors || {}).length > 0 || this.state.invalidFileMessage !== ""}
                                type="submit"
                              >
                                Continue
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>

                </Grid>
              </Grid>
            </Box>
          </StyleWrapperSetupPodcastProfile>
          <Footer navigation={this.props.navigation} id={"1"} />
        </div>
      </>

    );
  }

}
const StyleWrapperSetupPodcastProfile = styled('div')({
  height: "auto",
  paddingLeft: "calc(6% + 135px)",
  paddingRight: "5%",
  background: "black",
  "& .subContain": {
    position: "relative",
    paddingRight: "40px",
    height: "47rem",
    overflow: "hidden",
    scrollbarWidth: "thin",
    overflowY: "scroll",
    scrollbarColor: "#FF0807 #4D4D4D",
  },
  "& .btnSignUp": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "56px",
    background: "#B2B3B3",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "5%",
    marginBottom: "7%",
    fontWeight: 900,
    width: "392px",
    fontSize: "14px",
  },
  "& *": {
    boxSizing: "border-box",
  },
  "& .field": {
    width: "954px",
    height: "56px",
  },

  "& .btnSelectedSignUp": {
    cursor: "pointer",
    fontFamily: "Nexa-Heavy",
    height: "56px",
    color: "white",
    margin: "auto",
    display: "flex",
    borderRadius: "8px",
    marginTop: "5%",
    marginBottom: "7%",
    fontWeight: 900,
    width: "392px",
    fontSize: "14px",
    background: "#FF0807",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .title": {
    color: "white",
    fontFamily: "Nexa-Heavy",
    textTransform: "capitalize",
    paddingTop: "1%"
  },
  "& .btnBrowse": {
    height: "26px",
    width: "110px",
    fontFamily: "Nexa-Heavy",
    background: "#FF0807",
    color: "white",
    textTransform: "capitalize",
    borderRadius : "8px",
    fontSize : "10px"
  },
  "& .subContainer": {
    display: "flex",
    justifyContent: "center",
    height: "auto",
    width: "64%",
    marginTop: "28px",
  },
  "& .redContainer": {
    height: "80px",
    margin: 0,
    borderRadius: "16px 16px 0px 0px",
    backgroundColor: "#FF0807",    
  },
  "& .label": {
    fontFamily: "Nexa-Heavy",
    color: "white",
    fontSize: "14px",
    fontWeight: 700,
  },
  "& .mainTitle": {
    margin: "auto",
    height: "40px",
    color: "white",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    fontSize: "30px",
    marginBottom: "21px"
  },
  "& .container": {
    height: "auto",
    backgroundColor: "#312F2D",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItens: "center",
    margin: "auto",
    borderRadius: "0px 0px 8px 8px"
  },
})
const webStyles = {
  container: {
    height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
    overflowY: "scroll" as const,
    scrollbarWidth: "thin",
    scrollbarColor: "red gray",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "gray",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  } as const
}
// Customizable Area End
