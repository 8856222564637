// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button, Switch, MenuItem, FormControl, Select
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HostMonetizationOnlineAdvertisingController, {
    Props,
} from "./HostMonetizationOnlineAdvertisingController";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { goBack, imageAirlPlay, imageFullScreen, imagePlayCircle } from "./assets";
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {getStorageData} from "../../../framework/src/Utilities"

export default class HostMonetizationOnlineAdvertising extends HostMonetizationOnlineAdvertisingController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object().shape({
    })
    render() {
        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id='1' />
                <StyledWrapperOfferingConsulting>
                    <Formik
                        initialValues={{
                            photos: false,
                            photosChannel: false,
                            photosEpisode: false,
                            photosEpisodeFullScreen: false,
                            videos: false,
                            videosChannel: false,
                            videosEpisode: false,
                            videosFullScreen: false,
                            audioAdWithinAudioPodcast: false,
                            audioPreRolls: false,
                            audioPostRolls: false,
                            videoAdWithinVideoPodcast: false,
                            videoPreRoll: false,
                            videoPostRoll: false,
                            audioTextField: "",
                            videoTextField: "",
                        }}
                        validationSchema={this.validationSchema}
                        validateOnMount={true}
                        onSubmit={(values) => {
                            console.log(values, "values");
                            handleNavigation("", this.props)
                        }}
                        data-test-id="submitBtn"
                    >
                        {({ handleChange, handleSubmit, values, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <Box>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Box className="mainTitle" id="offeringConsulting" style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "27px", }}>
                                            <img
                                                id="offeringConsulting"
                                                src={goBack}
                                                alt="goBack"
                                                style={{ marginRight: "22px", cursor: "pointer", color: "white" }}
                                                data-test-id="handleNavigation"
                                                onClick={async()=>
                                                    {let dataExist =await getStorageData("monetizationCategories",true)
                                                        this.handleOnlineAdvertisingRoute(dataExist,"onlineAdvertising")}}
                                               
                                            />
                                            Online Advertising
                                        </Box>
                                        <Box className="upperBtn" style={{ marginLeft: "auto", marginRight: "10px" }}>
                                            Re-Set Up Monetization
                                        </Box>
                                        <Box className="upperBtn" style={{ marginRight: "48px" }} id="offeringConsulting">
                                            Save For Later
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                        <Box className="redContainer"></Box>
                                        <Box className="container">

                                            <Box className="subContainer">
                                                <Box className="boxS">
                                                    <Box className="row">
                                                        <Box className="rowLeft">Would you like To allow Display ads as photos</Box>
                                                        <Box className="rowRight">
                                                            <Switch
                                                                checked={values.photos}
                                                                data-test-id="photos"
                                                                onChange={() => setFieldValue('photos', !values.photos)}
                                                                value={values.photos}
                                                                name="photos"
                                                                color="default"
                                                                sx={{
                                                                    marginLeft: "2px",
                                                                    width: "80px",
                                                                    height: "50px",
                                                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                                                        transform: "translateX(16px)",
                                                                        color: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-switchBase": {
                                                                        color: "#fff",
                                                                        position: "absolute",
                                                                        top: "6px",
                                                                        left: ResolveCondition(values.photos ,
                                                                            "18px" ,
                                                                            "8px")
                                                                            ,
                                                                    },
                                                                    "& .MuiSwitch-thumb": {
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        backgroundColor: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-track": {
                                                                        backgroundColor: ResolveCondition(values.photos , "rgb(255, 8, 7)" , "#ccc"),

                                                                        opacity:ResolveCondition(!values.photos ,
                                                                            "0.5 "
                                                                            , "1 !important")
                                                                            ,
                                                                        borderRadius: "16px",
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <div style={{ opacity: ResolveCondition(values.photos,"1","0.5")}}>
                                                        <Box className="row">
                                                            <Box className="rowLeft"><img src={imagePlayCircle} alt="imagePlayCircle" /><span style={{ paddingLeft: "10px" }}>Channel</span></Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    checked={values.photosChannel}
                                                                    data-test-id="photosChannel"
                                                                    onChange={() => setFieldValue('photosChannel', !values.photosChannel)}
                                                                    value={values.photosChannel}
                                                                    name="photosChannel"
                                                                    color="default"
                                                                    disabled={values.photos ? false : true}
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:
                                                                                values.photosChannel ?
                                                                                    "18px" :
                                                                                    "8px",
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor: ResolveCondition(values.photosChannel ,"rgb(255, 8, 7)" , "#ccc"),
                                                                            opacity:ResolveCondition(!values.photosChannel ,
                                                                                "0.5 "
                                                                                ,"1 !important")
                                                                                ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row">
                                                            <Box className="rowLeft"><img src={imageAirlPlay} alt="imagePlayCircle" /><span style={{ paddingLeft: "10px" }}>Episode</span></Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    checked={values.photosEpisode}
                                                                    data-test-id="photosEpisode"
                                                                    onChange={() => setFieldValue('photosEpisode', !values.photosEpisode)}
                                                                    value={values.photosEpisode}
                                                                    disabled={values.photos ? false : true}
                                                                    name="photosEpisode"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:ResolveCondition(values.photosEpisode ,
                                                                                "18px" ,
                                                                                "8px"),
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor: ResolveCondition(values.photosEpisode , "rgb(255, 8, 7)" ,"#ccc") ,

                                                                            opacity:ResolveCondition( !values.photosEpisode, "0.5 ","1 !important"),                                                                            
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row">
                                                            <Box className="rowLeft"><img src={imageFullScreen} alt="imagePlayCircle" /><span style={{ paddingLeft: "10px" }}>Episode Full Screen</span></Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    checked={values.photosEpisodeFullScreen}
                                                                    data-test-id="photosEpisodeFullScreen"
                                                                    disabled={values.photos ? false : true}
                                                                    onChange={() => setFieldValue('photosEpisodeFullScreen', !values.photosEpisodeFullScreen)}
                                                                    value={values.photosEpisodeFullScreen}
                                                                    name="photosEpisodeFullScreen"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left: ResolveCondition(values.photosEpisodeFullScreen ,
                                                                                "18px" ,
                                                                                "8px"),
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor: ResolveCondition(values.photosEpisodeFullScreen , "rgb(255, 8, 7)" , "#ccc") ,
                                                                            opacity: ResolveCondition(!values.photosEpisodeFullScreen ,
                                                                                "0.5 "
                                                                                , "1 !important") ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                </Box>
                                                <Box className="boxS">
                                                    <Box className="row">
                                                        <Box className="rowLeft">Would you like To allow Display ads as videos?</Box>
                                                        <Box className="rowRight">
                                                            <Switch
                                                                checked={values.videos}
                                                                data-test-id="videos"
                                                                onChange={() => setFieldValue('videos', !values.videos)}
                                                                value={values.videos}
                                                                name="photosEpisode"
                                                                color="default"
                                                                sx={{
                                                                    marginLeft: "2px",
                                                                    width: "80px",
                                                                    height: "50px",
                                                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                                                        transform: "translateX(16px)",
                                                                        color: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-switchBase": {
                                                                        color: "#fff",
                                                                        position: "absolute",
                                                                        top: "6px",
                                                                        left:
                                                                            values.videos ?
                                                                                "18px" :
                                                                                "8px",
                                                                    },
                                                                    "& .MuiSwitch-thumb": {
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        backgroundColor: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-track": {
                                                                        backgroundColor: ResolveCondition(values.videos , "rgb(255, 8, 7)" ,"#ccc") ,

                                                                        opacity:ResolveCondition(  !values.videos ,
                                                                            "0.5 "
                                                                            ,"1 !important")
                                                                          ,
                                                                        borderRadius: "16px",
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <div style={{ opacity:ResolveCondition(values.videos , "1" ,"0.5")  }}>
                                                        <Box className="row">
                                                            <Box className="rowLeft"><img src={imagePlayCircle} alt="imagePlayCircle" /><span style={{ paddingLeft: "10px" }}>Channel</span></Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    checked={values.videosChannel}
                                                                    disabled={values.videos ? false : true}
                                                                    data-test-id="videosChannel"
                                                                    onChange={() => setFieldValue('videosChannel', !values.videosChannel)}
                                                                    value={values.videosChannel}
                                                                    name="videosChannel"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:ResolveCondition( values.videosChannel ,
                                                                                "18px" ,
                                                                                "8px"),
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor:ResolveCondition(values.videosChannel , "rgb(255, 8, 7)" , "#ccc") ,

                                                                            opacity:ResolveCondition( !values.videosChannel ,
                                                                                "0.5 "
                                                                                , "1 !important")
                                                                               ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row">
                                                            <Box className="rowLeft"><img src={imageAirlPlay} alt="imagePlayCircle" /><span style={{ paddingLeft: "10px" }}>Episode</span></Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    disabled={values.videos ? false : true}
                                                                    checked={values.videosEpisode}
                                                                    data-test-id="videosEpisode"
                                                                    onChange={() => setFieldValue('videosEpisode', !values.videosEpisode)}
                                                                    value={values.videosEpisode}
                                                                    name="videosEpisode"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:ResolveCondition(values.videosEpisode ,
                                                                                "18px" ,
                                                                                "8px")
                                                                                ,
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor:ResolveCondition(values.videosEpisode , "rgb(255, 8, 7)" , "#ccc") ,

                                                                            opacity:ResolveCondition( !values.videosEpisode ,
                                                                                "0.5 "
                                                                                ,"1 !important")
                                                                               ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row">
                                                            <Box className="rowLeft"><img src={imageFullScreen} alt="imagePlayCircle" /><span style={{ paddingLeft: "10px" }}>Episode Full Screen</span></Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    checked={values.videosFullScreen}
                                                                    disabled={values.videos ? false : true}
                                                                    data-test-id="videosFullScreen"
                                                                    onChange={() => setFieldValue('videosFullScreen', !values.videosFullScreen)}
                                                                    value={values.videosFullScreen}
                                                                    name="videosFullScreen"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:ResolveCondition( values.videosFullScreen ,
                                                                                "18px" ,
                                                                                "8px")
                                                                               ,
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor: ResolveCondition(values.videosFullScreen , "rgb(255, 8, 7)" , "#ccc"),

                                                                            opacity:ResolveCondition( !values.videosFullScreen ,
                                                                                "0.5 "
                                                                                , "1 !important")
                                                                               ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: "flex", justifyContent: "space-around", width: "80%", margin: "auto", marginTop: "45px", marginBottom: "71px" }}>
                                                <Box className="boxS" style={{ height: "434px" }}>
                                                    <Box className="row" style={{ paddingTop: "20px" }}>
                                                        <Box className="rowLeft">Would you like to have audio ads within your audio podcasts</Box>
                                                        <Box className="rowRight">
                                                            <Switch
                                                                checked={values.audioAdWithinAudioPodcast}
                                                                data-test-id="audioAdWithinAudioPodcast"
                                                                onChange={() => setFieldValue('audioAdWithinAudioPodcast', !values.audioAdWithinAudioPodcast)}
                                                                value={values.audioAdWithinAudioPodcast}
                                                                name="audioAdWithinAudioPodcast"
                                                                color="default"
                                                                sx={{
                                                                    marginLeft: "2px",
                                                                    width: "80px",
                                                                    height: "50px",
                                                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                                                        transform: "translateX(16px)",
                                                                        color: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-switchBase": {
                                                                        color: "#fff",
                                                                        position: "absolute",
                                                                        top: "6px",
                                                                        left:ResolveCondition( values.audioAdWithinAudioPodcast ,
                                                                            "18px" ,
                                                                            "8px")
                                                                           ,
                                                                    },
                                                                    "& .MuiSwitch-thumb": {
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        backgroundColor: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-track": {
                                                                        backgroundColor:ResolveCondition(values.audioAdWithinAudioPodcast , "rgb(255, 8, 7)" ,"#ccc") ,

                                                                        opacity:ResolveCondition(!values.audioAdWithinAudioPodcast ,
                                                                            "0.5 "
                                                                            , "1 !important")
                                                                            ,
                                                                        borderRadius: "16px",
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <div style={{ opacity:ResolveCondition(values.audioAdWithinAudioPodcast , "1" , "0.5")  }}>
                                                        <Box className="row" style={{ paddingTop: "20px" }}>
                                                            <Box className="rowLeft">Would you like audio ads in <br />the pre-roll?</Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    checked={values.audioPreRolls}
                                                                    disabled={values.audioAdWithinAudioPodcast ? false : true}
                                                                    data-test-id="audioPreRolls"
                                                                    onChange={() => setFieldValue('audioPreRolls', !values.audioPreRolls)}
                                                                    value={values.audioPreRolls}
                                                                    name="audioPreRolls"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:ResolveCondition( values.audioPreRolls ,
                                                                                "18px" ,
                                                                                "8px")
                                                                               ,
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor:ResolveCondition(values.audioPreRolls ,"rgb(255, 8, 7)" , "#ccc") ,

                                                                            opacity:ResolveCondition( !values.audioPreRolls ,
                                                                                "0.5 "
                                                                                , "1 !important")
                                                                               ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row" style={{ paddingTop: "20px" }}>
                                                            <Box className="rowLeft">Would you like audio ads in <br />the post-roll?</Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    disabled={values.audioAdWithinAudioPodcast ? false : true}
                                                                    checked={values.audioPostRolls}
                                                                    data-test-id="audioPostRolls"
                                                                    onChange={() => setFieldValue('audioPostRolls', !values.audioPostRolls)}
                                                                    value={values.audioPostRolls}
                                                                    name="audioPostRolls"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left: ResolveCondition( values.audioPostRolls ,
                                                                                "18px" ,
                                                                                "8px")
                                                                               ,
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor: ResolveCondition(values.audioPostRolls ,"rgb(255, 8, 7)" ,"#ccc"),

                                                                            opacity:ResolveCondition( !values.audioPostRolls ,
                                                                                "0.5 "
                                                                                , "1 !important")
                                                                               ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row" style={{ paddingTop: "20px" }}>
                                                            <Box className="rowLeft" style={{ width: "100%" }}>What is your preferred length of an audio ad?</Box>
                                                        </Box>
                                                    </div>
                                                    <FormControl
                                                        id="audioAd"
                                                        fullWidth
                                                        sx={{
                                                            width: "90%",
                                                            marginTop: "4%",
                                                            marginLeft: "18px",

                                                            "& .MuiOutlinedInput-root": {
                                                                "& fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderColor: ResolveCondition(values.audioAdWithinAudioPodcast , "#CBD5E1" , ""),
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderColor: "#CBD5E1",
                                                                },
                                                                "& .MuiSelect-select": {
                                                                    color: "#FFFFFF",
                                                                    "&::placeholder": {
                                                                        color: "#B0B0B0",
                                                                    },
                                                                },
                                                            },
                                                            "& .MuiSvgIcon-root": {
                                                                color: "#CBD5E1",
                                                            },
                                                            "& .MuiInputLabel-root": {
                                                                color: "#CBD5E1",
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                backgroundColor: "black",
                                                                color: "#CBD5E1",
                                                                "&:-webkit-autofill": {
                                                                    WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                    backgroundColor: "black !important",
                                                                    WebkitTextFillColor: "#CBD5E1 !important",
                                                                },
                                                            },
                                                        }}>
                                                        <Select
                                                            value={values.audioTextField}
                                                            disabled={values.audioAdWithinAudioPodcast ? false : true}
                                                            data-test-id="audioTextField"
                                                            onChange={handleChange}
                                                            name="audioTextField"
                                                            id="audioTextField"
                                                            displayEmpty
                                                            IconComponent={(props) => (
                                                                <KeyboardArrowDownIcon {...props}
                                                                    sx={{ color: "#CBD5E1" }} />
                                                            )}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        overflowY: 'auto',
                                                                        backgroundColor: '#030303',
                                                                        maxHeight: 250,
                                                                        "& .MuiMenuItem-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                        "& .Mui-selected": {
                                                                            backgroundColor: "#FF0807 !important",
                                                                            color: "#0F172A",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            sx={{
                                                                color: "#CBD5E1",
                                                                "&.Mui-disabled .MuiSvgIcon-root": {
                                                                    color: "#CBD5E1",
                                                                    opacity: 0.5,
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="15 Seconds" id = "audioAd" style={{ border: "1px solid white" }}>15 Seconds</MenuItem>
                                                            <MenuItem value="30 Seconds" id = "audioAd" style={{ border: "1px solid white" }}>30 Seconds</MenuItem>
                                                            <MenuItem value="45 Seconds" id = "audioAd" style={{ border: "1px solid white" }}>45 Seconds</MenuItem>
                                                            <MenuItem value="60 Seconds" id = "audioAd" style={{ border: "1px solid white" }}>60 Seconds</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box className="boxS" style={{ height: "434px" }}>
                                                    <Box className="row" style={{ paddingTop: "20px" }}>
                                                        <Box className="rowLeft">Would you like to have video ads within your video podcasts</Box>
                                                        <Box className="rowRight">
                                                            <Switch
                                                                checked={values.videoAdWithinVideoPodcast}
                                                                data-test-id="videoAdWithinVideoPodcast"
                                                                onChange={() => setFieldValue('videoAdWithinVideoPodcast', !values.videoAdWithinVideoPodcast)}
                                                                value={values.videoAdWithinVideoPodcast}
                                                                name="videoAdWithinVideoPodcast"
                                                                color="default"
                                                                sx={{
                                                                    marginLeft: "2px",
                                                                    width: "80px",
                                                                    height: "50px",
                                                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                                                        transform: "translateX(16px)",
                                                                        color: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-switchBase": {
                                                                        color: "#fff",
                                                                        position: "absolute",
                                                                        top: "6px",
                                                                        left:ResolveCondition(values.videoAdWithinVideoPodcast ,
                                                                            "18px" ,
                                                                            "8px")
                                                                            ,
                                                                    },
                                                                    "& .MuiSwitch-thumb": {
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        backgroundColor: "#fff",
                                                                    },
                                                                    "& .MuiSwitch-track": {
                                                                        backgroundColor:ResolveCondition(values.videoAdWithinVideoPodcast , "rgb(255, 8, 7)" , "#ccc") ,

                                                                        opacity:ResolveCondition( !values.videoAdWithinVideoPodcast ,
                                                                            "0.5 ",
                                                                            "1 !important")
                                                                           ,
                                                                        borderRadius: "16px",
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <div style={{ opacity: ResolveCondition(values.videoAdWithinVideoPodcast , "1" , "0.5") }}>

                                                        <Box className="row" style={{ paddingTop: "20px" }}>
                                                            <Box className="rowLeft">Would you like video ads in<br /> the pre-roll?</Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    checked={values.videoPreRoll}
                                                                    disabled={values.videoAdWithinVideoPodcast ? false : true}
                                                                    data-test-id="videoPreRoll"
                                                                    onChange={() => setFieldValue('videoPreRoll', !values.videoPreRoll)}
                                                                    value={values.videoPreRoll}
                                                                    name="videoPreRoll"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:ResolveCondition( values.videoPreRoll ,
                                                                                "18px" ,
                                                                                "8px")
                                                                               ,
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor:ResolveCondition(values.videoPreRoll , "rgb(255, 8, 7)" ,"#ccc") ,

                                                                            opacity:ResolveCondition(  !values.videoPreRoll ,
                                                                                "0.5 ",
                                                                                 "1 !important")
                                                                              ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row" style={{ paddingTop: "20px" }}>
                                                            <Box className="rowLeft">Would you like video ads in <br />the post-roll?</Box>
                                                            <Box className="rowRight">
                                                                <Switch
                                                                    disabled={values.videoAdWithinVideoPodcast ? false : true}
                                                                    checked={values.videoPostRoll}
                                                                    data-test-id="videoPostRoll"
                                                                    onChange={() => setFieldValue('videoPostRoll', !values.videoPostRoll)}
                                                                    value={values.videoPostRoll}
                                                                    name="videoPostRoll"
                                                                    color="default"
                                                                    sx={{
                                                                        marginLeft: "2px",
                                                                        width: "80px",
                                                                        height: "50px",
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            transform: "translateX(16px)",
                                                                            color: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-switchBase": {
                                                                            color: "#fff",
                                                                            position: "absolute",
                                                                            top: "6px",
                                                                            left:ResolveCondition( values.videoPostRoll ,
                                                                                "18px" ,
                                                                                "8px")
                                                                               ,
                                                                        },
                                                                        "& .MuiSwitch-thumb": {
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                        "& .MuiSwitch-track": {
                                                                            backgroundColor: ResolveCondition(values.videoPostRoll , "rgb(255, 8, 7)" , "#ccc"),

                                                                            opacity:ResolveCondition(!values.videoPostRoll ,
                                                                                "0.5 "
                                                                                , "1 !important")
                                                                                ,
                                                                            borderRadius: "16px",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box className="row" style={{ paddingTop: "20px" }}>
                                                            <Box className="rowLeft" style={{ width: "100%" }}>What is your preferred length of a video ad?</Box>

                                                        </Box>
                                                    </div>

                                                    <FormControl id="race" fullWidth sx={{
                                                        width: "90%",
                                                        marginTop: "4%",
                                                        marginLeft: "18px",
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: ResolveCondition(values.videoAdWithinVideoPodcast ,"#CBD5E1" , ""),
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#CBD5E1",
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#FFFFFF",
                                                                "&::placeholder": {
                                                                    color: "#B0B0B0",
                                                                },
                                                            },
                                                        },
                                                        "& .MuiSvgIcon-root": {
                                                            color: "#CBD5E1",
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: "#CBD5E1",
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            color: "#CBD5E1",
                                                            backgroundColor: "black",
                                                            "&:-webkit-autofill": {
                                                                backgroundColor: "black !important",
                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                            },
                                                        },
                                                    }}>
                                                        <Select
                                                            disabled={values.videoAdWithinVideoPodcast ? false : true}
                                                            value={values.videoTextField}
                                                            onChange={handleChange}
                                                            data-test-id="videoTextField"
                                                            name="videoTextField"
                                                            displayEmpty
                                                            id="videoTextField"
                                                            IconComponent={(props) => (
                                                                <KeyboardArrowDownIcon {...props} sx={{
                                                                    color: "#CBD5E1",
                                                                    opacity:ResolveCondition(values.videoAdWithinVideoPodcast , "1" , "0.5") ,
                                                                    pointerEvents:ResolveCondition(values.videoAdWithinVideoPodcast , "auto" ,"none") ,
                                                                }} />
                                                            )}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        maxHeight: 250,
                                                                        overflowY: 'auto',
                                                                        backgroundColor: '#030303',
                                                                        "& .MuiMenuItem-root": {
                                                                            color: "#FFFFFF",
                                                                        },
                                                                        "& .Mui-selected": {
                                                                            color: "#0F172A",
                                                                            backgroundColor: "#FF0807 !important",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            sx={{
                                                                color: "#CBD5E1",
                                                                "&.Mui-disabled .MuiSvgIcon-root": {
                                                                    opacity: 0.5,
                                                                    color: "#CBD5E1",
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="15 Seconds" style={{ border: "1px solid white" }}>15 Seconds</MenuItem>
                                                            <MenuItem value="30 Seconds" style={{ border: "1px solid white" }}>30 Seconds</MenuItem>
                                                            <MenuItem value="45 Seconds" style={{ border: "1px solid white" }}>45 Seconds</MenuItem>
                                                            <MenuItem value="60 Seconds" style={{ border: "1px solid white" }}>60 Seconds</MenuItem>
                                                        </Select>

                                                    </FormControl>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                    <Button className="btn buttonColor"
                                        data-test-id="dataNavigations"
                                        type="submit"                                   
                                    >Continue</Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </StyledWrapperOfferingConsulting>
            </>

        );
    }

}
const StyledWrapperOfferingConsulting = styled("div")({
    background: "black",
    paddingRight: "3%",
    paddingLeft: "calc(6% + 100px)",
    "& *": {
        boxSizing: "border-box",
    },
    "& .rowRight": {
        marginTop: "17px",
        marginRight: "17px"
    },
    "& .rowLeft": {
        fontFamily: "Nexa-Heavy",
        fontSize: "16px",
        width: "289px",
        textTransform: "capitalize",
        paddingLeft: "18px",
        paddingTop: "20px",
        display: "flex",
        alignItems: "center",
    },
    "& .row": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .boxS": {
        height: "295px",
        width: "415px",
        background: "#312F2D",
        borderRadius: "8px",
        "@media (max-width: 1300px)": {
            margin: "auto",
            marginTop: "20px"
        },
    },
    "& .redContainer": {
        margin: 0,
        height: "80px",
        borderRadius: "16px 16px 0px 0px",
        backgroundColor: "#FF0807",
        width: "95%"
    },
    "& .secTitle": {
        fontFamily: "Nexa-Regular",
        fontWeight: 100,
        textAlign: "center",
        paddingTop: "1.5%",
        fontSize: "16px",
        paddingBottom: "1%"
    },
    "& .upperBtn": {
        width: "auto",
        borderRadius: "8px",
        color: "white",
        height: "26px",
        fontWeight: 400,
        fontFamily: "Nexa-Heavy",
        marginRight: "26px",
        fontSize: "16px",
        background: "#312F2D",
    },
    "& .label": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "14px",
        fontWeight: 700,
    },
    "& .btn": {
        margin: "auto",
        height: "56px",
        marginTop: "5px",
        color: "white",
        cursor: "pointer",
        marginBottom: "73px",
        background: "#B2B3B3",
        width: "30%",
        borderRadius: "8px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        textTransform: "capitalize",
        fontSize: "14px",
        "&:hover": {
            background: "#B2B3B3",
        },
        "&:disabled": {
            color: "white",
        }
    },
    "& .buttonColor": {
        background: "#FF0807",
        "&:hover": {
            background: "#FF0807",
        }
    },
    "& .mainTitle": {
        margin: "auto",
        marginLeft: "2%",
        height: "40px",
        fontFamily: "Nexa-Heavy",
        marginBottom: "2%",
        fontSize: "30px",
        color: "white",
        fontWeight: 900,
        alignItems: "center",
        display: "flex",
    },
    "& .upperContent": {
        width: "100%",
        marginTop: "4%",
        marginBottom: "2%"
    },
    "& .title": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "30px",
        textAlign: "center",
        fontWeight: 900,
        textTransform: "capitalize"
    },
    "& .subContainer": {
        display: "flex",
        justifyContent: "space-around",
        width: "80%",
        margin: "auto",
        marginTop: "41px",
        "@media (max-width: 1300px)": {
            display: "flex",
            flexDirection: "column"
        },
    },
    "& .container": {
        color: "white",
        margin: "auto",
        height: "auto",
        marginBottom: "3%",
        width: "95%",
        background: "#44403C"
    },
})
// Customizable Area End