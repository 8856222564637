// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, avatar_edit_state } from "./assets";
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userId: number | null;
  userEmail: string;
  firstName: string;
  lastName: string;
  password: string;
  bio: string;
  dob: string;
  location: string;
  socialMedia: {
    facebook: string;
    linkedIn: string;
    instagram: string;
    x: string;
  };
  favoritePodcasts: string[];
  showPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  profilePicture: string;
  categoryList: CategoryStructure[];
  categories: CategoryStructure[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface CategoryStructure {
  "id": "8",
  "type": "category",
  "attributes": {
    "name": "science",
    "image": {
      "url": "/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d2d6992001eb7f2f2028b8dd19912c9a5e62764/img.jpg"
    },
    "created_at": "2024-09-17T07:22:23.376Z",
    "updated_at": "2024-09-17T07:22:23.391Z"
  }
}

export default class AccountSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public fileInputRef: React.RefObject<HTMLInputElement>;
  accountsApiCallId: string = "";
  podcastCategoryApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.fileInputRef = React.createRef<HTMLInputElement>();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userId: null,
      userEmail: "",
      firstName: "",
      lastName: "",
      password: "",
      bio: "",
      dob: "",
      location: "",
      socialMedia: {
        facebook: "",
        linkedIn: "",
        instagram: "",
        x: ""
      },
      favoritePodcasts: [],
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      profilePicture: avatar_edit_state,
      categoryList: [],
      categories: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getAccountsApi();
    this.categoryApi();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    await this.handleAccountsApi(from, message);
    const favPodcastIds = this.state.favoritePodcasts;
    await this.handleResForPodcastCategoryApi(from, message, favPodcastIds);
    // Customizable Area End
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RequestUserCredentials) === message.id) {
      const password = message.getData(getName(MessageEnum.LoginPassword));
      if (password) {
        this.setState({
          password: password,
        });
      }
    }
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start
  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.userEmail);
  }
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }))
  }
  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  handleAvatarClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      this.setState({ profilePicture: imageUrl })
    }
  };
  formatDateString = (dateString: string): string => {
    const [year, month, day] = dateString.split('-'); 
    const dateObj = new Date(Number(year), Number(month) - 1, Number(day)); 
    const monthName = dateObj.toLocaleString('default', { month: 'long' });
    return `${monthName} - ${day} - ${year}`;
};

  getAccountsApi = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.accountsApiType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleAccountsApi = async (from: string, message: Message) => {
    if (this.accountsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      try {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson) {
          this.setState({
            userId: responseJson.id,
            userEmail: responseJson.email,
            firstName: responseJson.first_name,
            lastName: responseJson.last_name,
            bio: responseJson.bio,
            location: responseJson.user_location,
            profilePicture: responseJson.profile_picture,
            dob: responseJson.date_of_birth,
            socialMedia: {
              facebook: responseJson.social_media_url.facebook,
              linkedIn: responseJson.social_media_url.linkedIn,
              instagram: responseJson.social_media_url.instagram,
              x: responseJson.social_media_url.x,
            },
            favoritePodcasts: responseJson.podcast_categories_ids
          });
        }
      } catch (error) {
        console.error('Error handling GET API response:', error);
      }
    }
  };
  categoryApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.podcastCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryDropdown
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForPodcastCategoryApi = async (from: string, message: Message, favPodcastIds: string[]) => {
    if (
      this.podcastCategoryApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({categories: responseJson.data})
        const categoryList = favPodcastIds.reduce((category, categoryId) => {
          const filteredData = responseJson.data.filter((item: CategoryStructure) => item.id == categoryId);
          const updatedCategory = category.concat(filteredData);  
          return updatedCategory;
        }, []);
        this.setState({ categoryList })
      }
    }
  };
  // Customizable Area End
}
// Customizable Area End
