import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { podcastImage1, podcastImage2 } from "./assets";

export interface Props {
  navigation: any;
  screenId: string;
}

interface SavedEpisode {
    imageUrl: string;
    selected: string;
    added: string;
    songName: string;
    artist: string;
}

interface S {
    savedEpisode: SavedEpisode[];
}

interface SS {
  screenId: string;
}

export default class ChannelLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
    channelLibraryId : string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        savedEpisode: [
            {
              selected: "selected",
              imageUrl: podcastImage1,
              songName: "Ep- look at me",
              added: "added",
              artist: "Tweenty thousand heartz"
            },
            {
                selected: "not selected",
                added: "added",
                artist: "Tweenty thousand heartz",
                songName: "Ep- look at me",
                imageUrl: podcastImage2,
            },
            {
              added: "added",
              imageUrl: podcastImage1,
              selected: "selected",
              songName: "Ep- look at me",
              artist: "Tweenty thousand heartz"
            },
            {
              imageUrl: podcastImage2,
              selected: "not selected",
              added: "added",
              songName: "Ep- look at me",
              artist: "Tweenty thousand heartz"
            },
            {
              imageUrl: podcastImage1,
              added: "added",
              selected: "selected",
              songName: "Ep- look at me",
              artist: "Tweenty thousand heartz"
            },
            {
              imageUrl: podcastImage2,
              selected: "selected",
              songName: "Ep- look at me",
              added: "added",
              artist: "Tweenty thousand heartz"
            },
            {
                added: "added",
                imageUrl: podcastImage1,
                selected: "selected",
                artist: "Tweenty thousand heartz",
                songName: "Ep- look at me",
            },
            {
              selected: "not selected",
              added: "added",
              artist: "Tweenty thousand heartz",
              songName: "Ep- look at me",
              imageUrl: podcastImage2,
          },
          {
            added: "added",
            imageUrl: podcastImage1,
            selected: "selected",
            songName: "Ep- look at me",
            artist: "Tweenty thousand heartz"
          },
          {
            artist: "Tweenty thousand heartz",
            imageUrl: podcastImage2,
            selected: "not selected",
            songName: "Ep- look at me",
            added: "added",
          },
          {
            added: "added",
            songName: "Ep- look at me",
            imageUrl: podcastImage1,
            selected: "selected",
            artist: "Tweenty thousand heartz"
          },
          {
            imageUrl: podcastImage2,
            songName: "Ep- look at me",
            selected: "selected",
            artist: "Tweenty thousand heartz",
            added: "added",
          },
          ]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

}